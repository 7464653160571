.partner-table {
    overflow-y: hidden;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    padding-top: 25px;

    .table-wrap {
        position: relative;
        width: 100%;
        padding: 0 15px;

        &::after,
        &::before {
            content: "";
            width: 20%;
            height: 45px;
            background: #F8FAFF;
            position: absolute;
            top: 0%;

        }

        &::after {
            left: 0%;
            transform: translate(-50%, 0%);
        }

        &::before {
            right: 0%;
            transform: translate(0%, 0%);
        }
    }

    .table {

        z-index: 1;
        position: relative;

        thead {
            background: #F8FAFF;
            border: transparent !important;


            tr {
                th {
                    padding: 12px 20px;
                    text-wrap: nowrap;
                    border: transparent !important;
                    color: #525866;
                    font-size: 14px;
                    font-weight: 700;
                }
            }



        }

        tbody {
            tr {

                td {
                    border-color: #f0f0f0;
                    text-wrap: nowrap;
                    padding: 12px 20px;

                    .total {
                        color: #525866;
                        font-size: 16px;
                        font-weight: 700;
                    }

                    &.else {
                        text-align: center;

                        svg {
                            width: 40px;
                            stroke: #c5c5c5;
                            margin-bottom: 10px;
                        }

                        h4 {
                            font-size: 14px;
                            margin: 0;
                        }
                    }
                }

                &:last-child {
                    border-bottom: 1px solid #f0f0f0 !important;
                }
            }
        }
    }
}

.table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    gap: 20px;

    .page-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        label {
            color: rgba(0, 0, 0, 0.42);
            font-size: 12px;
            letter-spacing: 0.5px;
            text-transform: uppercase;

            span {
                color: rgba(12, 12, 13, 0.87);
            }
        }

        .number-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            input {
                width: 38px;
                height: 38px;
                border-radius: 8px;
                border: 1px solid rgba(0, 0, 0, 0.15);

                @include flexcenter;

                &:focus-visible,
                &:focus,
                &:active {
                    border: 1px solid $primary;
                    outline: $primary;
                }

                &::placeholder {
                    color: rgba(0, 0, 0, 0.6) !important;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
            }
        }

    }
}

.bs-pagination-wrap {

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .arrows {
        svg {
            width: 20px;
            stroke: #000;
            stroke-width: 1.5px;
        }

        &.disabled {
            svg {
                stroke: rgba(0, 0, 0, 0.15);
            }
        }
    }


}

.bs-pagination {

    display: flex;
    list-style-type: none;
    padding: 2px;
    align-items: center;
    gap: 1px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0;
    padding: 0;


    .page-item {
        margin: 0 5px;
        width: 44px;
        height: 32px;
        @include flexcenter;

        svg {
            stroke: $primary;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .page-link {
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border: none;

            span {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: unset;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &.active {
            .page-link {
                border-radius: 8px;
                background: #FFF2F2;
                width: 44px;
                height: 32px;
            }
        }
    }
}

[data-bs-theme="dark"] {
    .partner-table {
        border: 1px solid #303030;
        background-color: #000000;

        .table-wrap {
            position: relative;
            width: 100%;
            padding: 0 15px;

            &::after,
            &::before {
                background: #303030;
            }

        }

        .table {
            thead {
                background-color: #303030;

                tr {

                    th {
                        border-right: 1px solid #303030;
                        border-bottom: 1px solid #303030;
                        color: white;
                    }
                }
            }

            tbody {
                tr {

                    td {
                        border-color: #303030;
                        color: $white;

                        &.else {
                            h4 {
                                color: $white;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: 1px solid#303030 !important;
                    }
                }
            }
        }
    }

    .table-footer {

        .page-info {
            label {
                color: rgba(255, 255, 255, 0.42);

                span {
                    color: rgba(255, 255, 255, 0.87);
                }
            }

            .number-select {
                input {
                    border: 1px solid rgba(245, 245, 245, 0.15);

                    &::placeholder {
                        color: rgba(255, 255, 255, 0.6) !important;
                    }
                }
            }

        }
    }

    .bs-pagination-wrap {
        .arrows {
            svg {
                stroke: #ffffff;
            }

            &.disabled {
                svg {
                    stroke: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }


    .bs-pagination {
        border: 1px solid rgba(251, 251, 251, 0.5);

        .page-item {
            .page-link {
                color: #ffffff;
            }

            &.active {
                .page-link {
                    color: #000000;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .partner-table {
        overflow-x: scroll;
    }

    .table-footer {
        flex-direction: column;
    }
}