.business-layout-wrap {
    background-color: #F0F2F7;
    min-height: 100vh;

    .header {
        position: fixed;
        top: 0;
        z-index: 10;
    }

    .business-content {
        padding: 80px 0;
    }
}

[data-bs-theme="dark"] {
    .business-layout-wrap {
        background-color: #17181A;
    }
}

.reponsive-info-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.bs-modal {
    .modal-header {
        border-bottom: none;

        .modal-title {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 3px;
                height: 3px;
                width: 20px;
                background-color: $primary;
                border-radius: 10px;
            }
        }

        >.btn-close {
            width: 20px;
            height: 20px;

            &:focus,
            &:active {
                box-shadow: none;
            }
        }

        .h4 {
            font-size: 1rem;
        }
    }

    .modal-body {
        .balance {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;

            label {
                color: #525866;
                font-size: 16px;
                font-weight: 500;
            }

            span {
                color: $primary;
                font-size: 28px;
                font-weight: 600;
            }
        }

        .modal-footer {
            border-top: none;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            padding: 17px 0px;
            padding-bottom: 0;
        }
    }

    .upload-img {
        width: auto !important;

        &.model-pink {
            background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
        }
    }
}

.form-check-bs {
    border: 1px solid $primary;

    &:checked {

        background-color: $primary;
        border-color: $primary;
    }
}

.action-icon {
    width: 20px;
    cursor: pointer;
}

.icon-form-group {
    label {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    #countries-box {
        padding-left: 53px;
    }

    .icon-input {
        >img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            width: 22px;
        }
    }
}

.country-input {
    width: 350px;
}

.input-button-row {
    display: flex;
    gap: 20px;

    .icon-input {
        width: 100%;
    }
}

.form-check-input {
    &:checked {
        background-color: $primary;
        border-color: $primary;
    }
}


.icon-input {
    position: relative;
    margin-bottom: 16px;

    input,
    textarea {
        display: flex;
        width: 100%;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding-right: 41px;

        &.disabled {
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: rgba(0, 0, 0, 0.05);
        }

        &:focus-visible,
        &:focus,
        &:active {
            border: 1px solid $primary;
            outline: $primary;
            box-shadow: none;
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.6) !important;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }
    }

    input {
        height: 56px;
        text-overflow: ellipsis;
    }

    input[type="date"] {
        padding-right: 16px;
        display: block;
        width: 100%;
    }

    .form-select {
        display: flex;
        width: 100%;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        height: 56px;
        color: rgba(0, 0, 0, 0.6) !important;
        padding-right: 41px;

        &:focus-visible,
        &:focus,
        &:active {
            border: 1px solid $primary;
            outline: $primary;
        }

        &::placeholder {
            color: rgba(0, 0, 0, 0.6);
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;

        svg {
            width: 20px;
            cursor: pointer;
        }
    }
}

.partner-info-card {
    position: relative;
    border-radius: 20px;
    background: #ECF2FF;
    width: 200px;
    height: 196px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding: 9px;
    padding-top: 20px;
    cursor: pointer;

    i {
        position: absolute;
        top: 0px;
        right: -9px;
        display: inline-flex;
        align-items: center;
        color: #ecf2ff;
        background: $primary;
        border-radius: 30px;
        height: 27px;
        min-width: 20px;
        padding: 10px 20px 10px 11px;
    }

    label {
        color: $primary;
        cursor: pointer;
    }

    .value {
        background: $primary;
        border-radius: 50px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        color: #ecf2ff;
    }

    .icon {
        span {
            font-size: 48px;
            color: $primary;
        }
    }
}


.Partner-title {
    position: relative;
    font-size: 30px;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 3px;
        height: 3px;
        width: 20px;
        background-color: $primary;
        border-radius: 10px;
    }
}

[data-bs-theme="dark"] {
    .switch-box-bs {
        .switch-box-slider {
            border: 1px solid rgba(0, 0, 0, 0.1);

            &:checked {
                background: #999;

            }
        }
    }

    .switch-box.is-primary .switch-box-input:checked~.switch-box-slider {
        background: #999;
    }

    .partner-info-card {
        background: #000000;

        i {
            color: #ecf2ff;
            background: $orange;
        }

        label {
            color: $orange;
        }

        .value {
            background: $orange;

            color: #ecf2ff;
        }

        .icon {
            span {
                color: $orange;
            }
        }
    }

    .Partner-title {
        h4 {
            &::before {
                background-color: $orange;
            }
        }

    }

    .icon-input {

        input {
            border: 1px solid rgba(255, 255, 255, 0.15);
            background-color: #17181A;
            color: $white;

            &::placeholder {
                color: rgba(255, 255, 255, 0.6) !important;
            }
        }

        .form-select {
            border: 1px solid rgba(255, 255, 255, 0.15);
            background-color: #17181A;
            color: $white !important;

            &::placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
        }

        svg {
            stroke: $white;
        }
    }

    .icon-form-group {
        label {
            color: #c3c3c3;
        }
    }

    .bs-modal {

        .modal-header {
            border-bottom: none;

            .modal-title {
                color: $white;
            }

            .btn-close {
                color: #ffffff;
                /* White color */
                background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
            }

        }

        .modal-content {
            background-color: #17181a;
        }

        .modal-body {
            color: $white;

            .balance {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;
                width: 100%;
                margin-bottom: 10px;

                label {
                    color: #525866;
                    font-size: 16px;
                    font-weight: 500;
                }

                span {
                    color: $primary;
                    font-size: 28px;
                    font-weight: 600;
                }
            }

            .modal-footer {
                border-top: none;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 10px;
                padding: 17px 0px;
                padding-bottom: 0;
            }
        }

        .upload-img {
            width: auto !important;

            &.model-pink {
                background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
            }
        }
    }

}

header {
    width: 100%;
    padding: 0px 10px;
    background: $white;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .brand {
        margin-left: 20px;

        img {
            width: 65px;
        }
    }

    .arrow-menu {
        .dropdown-toggle {
            border: 1px solid #F0F2F7;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            @include flexcenter;
            background-color: $white;

            span {
                color: #525866;
            }

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            padding: 10px;

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                a {
                    color: #525866;
                    transition: 0.3s all;

                    svg {
                        width: 20px;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: $primary;
                        transition: width .3;
                    }
                }

            }

        }
    }

    .navbar {
        padding: 0;
    }

    .header-wrap {
        .header-menu {
            display: flex;
            margin: 0;
            padding: 0;
            list-style-type: none;

            .header-menu-item {
                .header-menu-link {
                    padding: .5rem 1.4rem;
                    font-size: 12px;
                    font-weight: bold;
                    transition: 0.3s all;
                    @include flexcenter;
                    flex-direction: column;
                    position: relative;
                    color: #525866;

                    svg {
                        width: 20px;
                        margin-bottom: 5px;
                    }

                    &:hover {
                        transition: width .3;

                        &::before {
                            position: absolute;
                            content: '';
                            bottom: 0;
                            width: 20px;
                            height: 3px;
                            background: $primary;
                            border-bottom: 1px solid $primary;
                            border-radius: 5px;
                        }
                    }

                    &.active {
                        color: $primary !important;

                        svg {
                            stroke: $primary;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            bottom: 0;
                            width: 20px;
                            height: 3px;
                            background: $primary;
                            border-bottom: 1px solid $primary;
                            border-radius: 5px;
                        }
                    }

                }
            }
        }
    }

    >.menu {
        display: none;
    }

    .menu-dropdown {
        .dropdown-toggle {
            display: flex;
            align-items: center;
            border-radius: 25px;
            border: 1px solid rgba(0, 0, 0, 0.10);
            overflow: hidden;
            height: 30px;

            .label {
                color: $primary;
                background-color: #FFF2F4;
                border-radius: 25px;
                height: 30px;
            }

            svg {
                color: #525866;
            }

            &::after {
                display: none;
            }
        }


    }

    .header-info {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}

.bs-notifications {
    font-family: $poppin;

    .dropdown-toggle {
        border: transparent;
        @include flexcenter;
        background-color: $white !important;
        position: relative;

        svg {
            stroke: #525866;
            width: 30px;
        }

        .notifications-icon {
            &.active {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $primary;
                @include flexcenter;
                flex-shrink: 0;
                position: absolute;
                top: 0%;
                right: 15%;
                transform: translate(0, 0);
            }
        }


        &::after {
            display: none;
        }
    }

    svg {
        width: 20px;
    }

    .notifications-wrap {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
        width: 360px;
        min-width: 260px;
        padding: 0 0 5px 0 !important;
        overflow: hidden;
    }

    .noifications-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        .notifications_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 15px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            >label {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
            }

            >ul {
                display: flex;
                padding: 0;
                margin: 0;
                list-style-type: none;
                align-items: center;
                gap: 20px;

                li {
                    >div {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                    }

                    span {
                        color: #525966;
                        font-family: $poppin;
                        font-size: 12px;
                        font-weight: 700;
                    }

                    svg {
                        color: #525966;
                    }

                    &:hover,
                    &:active {
                        span {
                            color: $primary;
                        }

                        svg {
                            stroke: $primary;
                        }
                    }
                }
            }
        }

        .notification-list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            list-style-type: none;
            padding: 0;
            margin: 0;
            height: 75vh;
            overflow-y: scroll;


            li {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                padding: 10px 10px 10px 30px;
                position: relative;

                .massage-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 5px;

                    h5 {
                        color: #000;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 16px;
                        margin: 0;
                        color: #525866;
                    }

                    p {
                        margin: 0;
                        font-size: 13px;
                        line-height: 16px;
                    }

                    span {
                        color: #525866;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }


                .indicator {
                    &.active {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: $primary;
                        @include flexcenter;
                        flex-shrink: 0;
                        position: absolute;
                        top: 50%;
                        left: 5px;
                        transform: translate(50%, 0);
                    }

                }

                >svg {
                    cursor: pointer;
                    flex-shrink: 0;
                    width: 17px;
                    z-index: 5;
                }

                &:hover {
                    background: #F5F7FA !important;
                }

                &.active {
                    background-color: #FFF2F4;
                }
            }
        }

    }

    .dropdown-menu {
        padding: 10px;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            a {
                color: #525866;
                transition: 0.3s all;

                svg {
                    width: 20px;
                    margin-right: 10px;
                }

                &:hover {
                    color: $primary;
                    transition: width .3;
                }
            }

        }

    }
}

[data-bs-theme="dark"] {
    header {
        background: #000;

        .arrow-menu {
            .dropdown-toggle {
                border: 1px solid #181818;
                background-color: #000;

                span {
                    color: #B6BABF;
                }
            }

            .dropdown-menu {
                padding: 10px;

                li {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    a {
                        color: #B6BABF;
                    }

                }

            }
        }


        .menu-dropdown {
            .dropdown-toggle {
                display: flex;
                align-items: center;
                border-radius: 25px;
                border: 1px solid rgba(0, 0, 0, 0.10);
                overflow: hidden;
                height: 30px;

                .label {
                    color: $primary;
                    background-color: #FFF2F4;
                    border-radius: 25px;
                    height: 30px;
                }

                svg {
                    color: #525866;
                }

                &::after {
                    display: none;
                }
            }


        }

        .header-wrap {
            .header-menu {


                .header-menu-item {
                    .header-menu-link {

                        color: rgba(255, 255, 255, 0.5);

                        svg {
                            color: rgba(255, 255, 255, 0.5);
                        }





                    }
                }
            }
        }
    }

    .bs-notifications {

        .dropdown-toggle {
            background-color: #000 !important;
        }

        .notifications-wrap {
            background: #000000;
            box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);
        }

        .noifications-content {


            .notifications_head {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                >label {
                    color: #ffffff;
                }
            }

            .notification-list {



                li {

                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                    >svg {
                        stroke: $white;
                    }

                    .massage-content {

                        h5 {
                            color: $primary;
                        }

                        p {
                            color: $white;
                        }

                        span {
                            color: #525866;
                        }
                    }


                    .indicator {
                        &.active {
                            background-color: $primary;
                        }

                    }

                    &:hover {
                        background: #17181a !important;
                    }

                    &.active {
                        background-color: #1c0301;
                    }
                }
            }

        }

        .dropdown-menu {
            padding: 10px;

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                a {
                    color: #525866;
                    transition: 0.3s all;

                    svg {
                        width: 20px;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: $primary;
                        transition: width .3;
                    }
                }

            }

        }
    }
}

.sub-dropdown {



    .dropdown-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 5px;
        background: transparent;
        padding: 0;
        border-radius: 25px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        overflow: hidden;

        &::after {
            display: none;
        }
    }

    label {
        height: 30px;
        padding: 0 15px;
        background-color: #FFF2F4;
        border-radius: 25px;
        color: $primary;
        @include flexcenter;
    }

    .dropdown-menu {
        width: fit-content !important;
        background-color: #FFF2F4 !important;
        padding: 12px 20px !important;

        li {
            margin-top: 5px !important;
            cursor: pointer;

            &:hover {
                span {
                    color: #000;
                }
            }

            span {
                color: $primary;
            }

            &:first-child {
                margin-top: 0 !important;
            }

            &:last-child {
                border-top: none !important;
                padding: 0 !important;
            }
        }
    }

}

[data-bs-theme="dark"] {
    .sub-dropdown {
        border: 1px solid rgba(255, 255, 255, 0.1);

        label {
            color: $primary !important;
        }

        span {
            color: $white;
        }

    }
}

.profile-menu {
    .profile-toggle {
        border: 1px solid #F0F2F7;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        @include flexcenter;
        background-color: transparent;

        img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
        }

        svg {
            color: #525866;
        }

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        padding: 15px;
        width: 260px;
        border-radius: 16px;
        border: transparent;
        margin-top: 5px;
        background: #FFF;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);

        .profile-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1.5px solid #F0F2F7;
            padding: 0 0 10px 0;

            .info {
                width: 90%;
                overflow: hidden;

                h1 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                p {
                    margin: 0;
                    font-size: 12px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .profile {
                border: 1px solid #F0F2F7;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                @include flexcenter;
                background-color: #F0F2F7;
                flex-shrink: 0;

                img {
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                }

            }
        }

        .level {
            text-transform: capitalize;
            padding: 5px 10px;
            background-color: #E6E9F2;
            border-radius: 20px;
            border-top-right-radius: 0;
            font-size: 12px;
            font-weight: 500;
        }

        >li {

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;

            label {
                font-size: 13px;
                font-weight: 500;
            }

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                width: 100%;
                color: $black;

                label {
                    cursor: pointer;
                }

                svg {
                    width: 20px;
                }
            }

            .mode {
                display: flex;
                align-items: center;
                border-radius: 25px;
                border: 1px solid rgba(0, 0, 0, 0.10);
                overflow: hidden;
                height: 30px;

                .theme {
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    padding: 0 12px;

                    &.active {
                        background-color: #FFF2F4;
                        border-radius: 25px;

                        svg {
                            color: $primary;
                        }
                    }

                    svg {
                        width: 20px;
                        color: #000;
                    }

                }

                svg {
                    width: 20px;
                }

                .form-switch {
                    padding-left: 45px;

                    .form-check-input:checked {
                        background-color: $primary !important;
                        border-color: $primary !important;
                    }
                }

            }

            &:last-child {
                border-top: 1.5px solid #F0F2F7;
                padding: 10px 0 0 0;

                .sign-out {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    svg {
                        width: 20px;
                    }
                }
            }

        }

    }
}

[data-bs-theme="dark"] {

    .profile-menu {
        .profile-toggle {
            border: 1px solid #181818;

            svg {
                color: #525866;
            }
        }

        .dropdown-menu {
            background: #000;
            box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);

            .profile-info {
                border-bottom: 1.5px solid #B6BABF;

                .info {

                    h1,
                    p {
                        color: $white;
                    }
                }

                .profile {
                    border: 1px solid #000000;
                    background-color: #181818;

                    svg {
                        width: 30px;
                        fill: #525866;
                    }
                }
            }


            li {

                label,
                a {
                    color: $white;
                }

                .mode {
                    border: 1px solid #181818;

                    .theme {
                        svg {
                            stroke: $white;
                        }

                    }
                }

                &:last-child {
                    border-top: 1.5px solid #B6BABF;
                }

            }

        }
    }
}

.spinner,
.spinner-border {
    color: $primary !important;
}

.business-signin-form {
    background-color: #F0F2F7;
    position: relative;
    height: 100vh;
    font-family: $poppin;

    &.login-page {
        overflow: hidden;
    }

    &.signup-page {
        overflow-x: hidden;

        .login_box {
            margin: 5% auto;
        }
    }

    >img {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
    }

    .login_box {
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        width: 480px;
        margin: auto;
        padding: 32px 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .login_box_head {
            text-align: center;

            img {
                width: 70px;
                margin-bottom: 15px;
            }

            h4 {
                color: #000;
                font-size: 16px;
                letter-spacing: 0.15px;
                margin-bottom: 16px;
            }
        }

        .login_box_body {
            >h4 {
                font-size: 16px;
                font-weight: 700;
                margin-top: 15px;
            }

            >p {
                font-size: 16px;
            }

            .link {
                color: #525866;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0.4px;
                text-decoration-line: underline;
                text-transform: uppercase;
                margin: 16px;
                display: block;
            }

            .terms {
                display: flex;
                gap: 10px;

                .link {
                    margin: 0;
                }
            }
        }
    }



    .checkbox {
        color: $white;
    }

    .form-check-input {
        border-color: $primary;
        color: $white;
    }

    .logo {
        display: inline-block;
        text-align: center;
        width: 100%;

        img {
            width: 123px;
            margin-bottom: 23px;
            display: inline-block;
        }
    }


}

.error-massage {
    text-align: center;
    color: $primary;
}

@media screen and (max-width:767px) {
    .business-layout-wrap {
        padding-bottom: 50px;
    }

    .country-input {
        width: 185px !important;
    }

    header {
        width: 100%;
        padding: 0px 10px;
        background: $white;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .brand {
            img {
                width: 65px;
            }
        }

        >.menu {
            display: block;
        }

        .navbar {
            padding: 0;
        }

        .header-wrap {

            display: none;
            position: absolute;
            width: 96%;
            left: 0;
            top: 57px;
            transform: translate(10px, 10px);

            &.active {
                display: block;
            }

            .header-menu {
                flex-direction: column;
                border-radius: 16px;
                background: #FFF;
                box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);

                padding: 20px 0;

                .header-menu-item {
                    width: 100%;

                    .header-menu-link {

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row-reverse;

                        svg {
                            width: 20px;
                            margin-bottom: 5px;
                        }

                        &:hover {
                            transition: width .3;

                            &::before {
                                position: absolute;
                                content: '';
                                bottom: 0;
                                width: 20px;
                                height: 3px;
                                background: $primary;
                                border-bottom: 1px solid $primary;
                                border-radius: 5px;
                            }
                        }

                        &.active {
                            background: rgba(240, 242, 247, 1);
                            color: black;

                            &::before {
                                display: none;
                            }
                        }


                    }

                    &:nth-child(4) {
                        .header-menu-link {
                            border-bottom: 1px solid rgba(240, 242, 247, 1);
                        }
                    }
                }
            }
        }

        .menu-dropdown {
            .dropdown-toggle {
                display: flex;
                align-items: center;
                border-radius: 25px;
                border: 1px solid rgba(0, 0, 0, 0.10);
                overflow: hidden;
                height: 30px;

                .label {
                    color: $primary;
                    background-color: #FFF2F4;
                    border-radius: 25px;
                    height: 30px;
                }

                svg {
                    color: #525866;
                }

                &::after {
                    display: none;
                }
            }


        }
    }

    .business-signin-form {
        background: linear-gradient(45deg, $primary 0%, $primary 25%, #FFF 25%, rgba(255, 255, 255, 0.00) 50%), #FFF;

        >img {
            display: none;
        }

        .login_box {
            width: 90% !important;
            padding: 32px 16px;

            .login_box_head {
                top: 40%;

                h4 {
                    font-size: 13px;
                }
            }

            .link {
                font-size: 11px;
            }

        }



        .checkbox {
            color: $white;
        }

        .form-check-input {
            border-color: $primary;
            color: $white;
        }


        .logo {
            display: inline-block;
            text-align: center;
            width: 100%;

            img {
                width: 123px;
                margin-bottom: 23px;
                display: inline-block;
            }
        }


    }

    [data-bs-theme="dark"] {
        header {

            .header-wrap {

                .header-menu {
                    border-radius: 16px;
                    border: 1px solid rgba(255, 255, 255, 0.10);
                    background: #141414;
                    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);

                    .header-menu-item {

                        .header-menu-link {

                            &.active {
                                background: rgb(0, 0, 0);
                                color: rgb(255, 255, 255);
                            }


                        }

                        &:nth-child(4) {
                            .header-menu-link {
                                border-bottom: 1px solid rgb(0, 0, 0);
                            }
                        }
                    }
                }
            }

        }
    }

    .icon-input {
        input {
            font-size: 16px;
        }
    }

    .profile-menu {
        .dropdown-menu {
            .profile-info {
                .profile {
                    display: none;
                }
            }
        }
    }
}