.auth_style_2 {
    min-height: 100vh;
    
    padding-top: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 40px;
    background-attachment: fixed;
    background-color: black;
    background-size: cover;

    >img {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 256px;
        margin-bottom: 15px;
    }

    .auth_wraper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .auth_card {
            width: 500px;
            max-width: 500px;
            margin: auto;
            border-radius: 40px;
            background: rgb(180, 164, 88);
            background: linear-gradient(270deg, rgb(231, 232, 231) 0%, rgb(180, 164, 88) 100%) !important;

            .auth_head {

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                background: rgb(200, 158, 72);
                background: linear-gradient(269.94deg, #CDA134 0.06%, #C49122 70.06%, #E1B446 99.96%);
                color: #FFF;
                border-radius: 25px 25px 0 0;
                padding: 15px 25px;

                h1 {
                    font-size: 1.7rem;
                    font-weight: 600;
                }

                span {
                    font-size: 10px;
                    font-size: 11px;
                }
            }

            .auth_body {
                padding: 20px 25px;
                background-color: #FAFAFA;
                border-radius: 0 0 25px 25px;

                .verify {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;

                    .Character {
                        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                        background-color: $primary;
                        width: 40%;
                        min-height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #ffd679;
                        text-shadow: 1px solid black;
                        letter-spacing: 0.6rem;
                        font-style: italic;
                        -webkit-user-select: none;
                        /* Safari */
                        -moz-user-select: none;
                        /* Firefox */
                        -ms-user-select: none;
                        /* Internet Explorer/Edge */
                        user-select: none;
                    }

                    .form-group {
                        width: 60%;
                    }
                }
            }

            label {
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 5px;
            }

            .inputwrap {

                background: rgb(180, 164, 88);
                background: linear-gradient(270deg, rgb(231, 232, 231) 0%, rgb(180, 164, 88) 100%) !important;
                border-radius: 10px;
                padding: 2px;
                position: relative;

                .form-control {
                    border: none;
                    border-radius: 8px;
                    background-color: #FAFAFA;
                    min-height: 40px;

                }
            }
        }

    }
}

.lock-icon {
    position: absolute;
    top: 0px;
    right: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50px;
    height: 100%;
    cursor: pointer;

    &::before {
        position: absolute;
        content: "";
        background: linear-gradient(0deg, rgb(231, 232, 231) 0%, rgb(180, 164, 88) 100%) !important;
        height: 38px;
        width: 2px;
        top: 3px;
        left: 0;
    }
}

.top50 {
    top: 50px !important;
}

.uncompleted {
    font-size: 12px;
}

.completed {
    color: $primary;
    font-size: 12px;
}

.error-message {
    color: red;
    font-size: 12px;
}

@media screen and (max-width : 767px) {
    .auth_style_2 {
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 20px;


        >img {
            width: 174px;
        }

        .auth_wraper {
            display: flex;
            flex-direction: column;
            padding: 10px;
            justify-content: center;
            align-items: center;
        }

        .auth_card {
            width: 100% !important;
            margin: 0px 15px;

            .inputwrap {
                .form-control {
                    font-size: 16px !important;
                }
            }

            .auth_body {
                .verify {
                    .Character {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}