body {
	font-family: $font-primary;
	//padding-top: 126px;
	top: 0 !important;
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $secondary;
	}
}


.skiptranslate {
    display: none;
}


[data-bs-theme="dark"] {
   .business-layout-wrap {
	color: #FFF;
   }
}

@media screen and (max-width: 767px) {
	body,html,* {
		font-size: 14px;
	}
}