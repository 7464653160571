.svg-ellipse-chart {

    filter: drop-shadow(0px 4px 16px rgba(100, 100, 111, 0.10));

    .svg-ellipse-chart-circle {
        animation: circle-chart-fill 2s reverse;
        transform: rotate(-90deg);
        transform-origin: center;
        fill: #ffffff;

    }

    .svg-ellipse-chart-background {
        stroke: #ffffff;
    }


    .svg-ellipse-chart-info {
        animation: circle-chart-appear 2s forwards;
        opacity: 0;
        transform: translateY(0.3em);

        .svg-ellipse-chart-percent,
        .svg-ellipse-chart-subline {
            fill: #000000;
        }
    }

    @keyframes circle-chart-fill {
        to {
            stroke-dasharray: 0 100;
        }
    }

    @keyframes circle-chart-appear {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
[data-bs-theme="dark"] {

    .svg-ellipse-chart {


        .svg-ellipse-chart-circle {
            fill: #141414;

        }

        .svg-ellipse-chart-background {
            stroke: #141414;
        }


        .svg-ellipse-chart-info {

            .svg-ellipse-chart-percent,
            .svg-ellipse-chart-subline {
                fill: #fff;
            }
        }
    }

}

@media screen and (max-width:767px) {
    .svg-ellipse-chart {
        filter: none;
        .svg-ellipse-chart-info {
            .svg-ellipse-chart-percent {
                font-size: 3.5px;
            }

            .svg-ellipse-chart-subline {
                font-size: 2px;
            }
        }

    }
}