.bonus_page_head {
    h3 {
        font-weight: 600;
        font-size: 34px;
        color: $black;
    }

    p {
        color: rgb(90, 90, 90);
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
    }
}

.bonus_total {
    h6 {
        font-size: 12px;
        font-weight: bold;
        color: rgb(142, 142, 142);
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 34px;
        font-weight: 700;
    }
}

.tier-item {
    margin-top: 1.3rem;
    h4 {
        font-size: 18px;
        font-weight: 600;
        color: rgb(0, 0, 0);
    }

    .card-gradient {
        .card-inner {
            padding: 20px 40px;
        }
    }

    .tier_packages {
        p {
            font-size: 16px;
            font-weight: 600;
            margin: 0
        }
    }

    .tier_pool {
        @include gradientReverse();
        padding: 2px;
        border-radius: 12px;

        .inner {
            background-color: $lightGray;
            border-radius: 10px;
            padding: 10px;
            min-height: 72px;

            &.status {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    color: #ffffff;
                    background-color: #000;
                    border-radius: 50px;
                    font-size: 30px;
                }
            }
        }

        p {
            margin: 0;
            font-size: 13px;
        }
        h3 {
            margin: 10px 0 0 0;
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.table_matrix_levels {
    th {
        text-align: center;
    }
    td {
        width: 33%;
        text-align: center;
        padding: 9px;
        vertical-align: middle;
        span {
            line-height: unset;
            &.yes {
                color: #FFF;
                background-color: #000;
            }
            &.no {
                color: #D61D1D;
            }
        }
    }
}
.bonus_page_head{
    text-align: justify;
}
@media screen and (max-width: 767px) {
    .bonus_page_head {
        h3 {
            font-size: 20px;
        }
    }
    .tier-item {
        .tier_pool {
            h3 {
                font-size: 16px;
            }
        }
    }
}