.network-list {
    font-family: $poppin;
    margin-top: 10px;


    ul {
        list-style: none;
        padding: 0;

        li {
            .tree-card-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 8px;
                background: #FFF;
                border: 1px solid rgba(0, 0, 0, 0.03);
                min-width: 100%;
                padding: 10px;
                margin-bottom: 10px;
                position: relative;




                &:hover {
                    border: 1px solid $primary;
                }

                .tree-user-profile {
                    width: 22.33%;
                }

                .rank-img {
                    width: 10.33%;

                    .photo {
                        width: 44px;
                        height: 44px;

                        img {
                            width: 100%;
                        }
                    }

                }

                .tree-card-center {
                    width: 33.33%;

                    >ul {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 15px;

                        li {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 8px;
                            width: 33.33%;

                            .icon {
                                svg {
                                    width: 25px;
                                    stroke: $primary;
                                }

                                &.pink {
                                    svg {
                                        stroke: rgba(255, 0, 43, 1);
                                    }
                                }

                                &.orange {
                                    svg {
                                        stroke: rgba(255, 96, 64, 1);
                                    }
                                }

                                &.red {
                                    svg {
                                        stroke: rgba(255, 0, 43, 1);
                                    }
                                }
                            }

                            .tree-info {
                                >span {
                                    color: #000;
                                    font-size: 16px;
                                    line-height: 100%;
                                    text-wrap: nowrap;
                                }

                                p {
                                    color: #525866;
                                    font-size: 14px;
                                    line-height: 16px;
                                    margin: 0;

                                }
                            }
                        }
                    }

                }

                .counting-div {
                    width: 22.33%;
                    @include flexcenter;



                    span {
                        background: $primary;
                        color: #fff;
                        padding: 0px 22px;
                        border-radius: 20px;
                        font-size: 16px;
                        @include flexcenter;
                        text-wrap: nowrap;
                    }
                }

                .tree-card-end {
                    width: 22.33%;
                    display: flex;
                    justify-content: flex-end;

                    ul {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 16px;
                        list-style-type: none;

                        li {
                            .level {
                                @include flexcenter;
                                flex-direction: column;
                                padding: 12px 16px;
                                border-radius: 50%;
                                border: 1px solid $primary;
                                background: #FFF2F4;
                                flex-shrink: 0;
                                width: 64px;
                                height: 64px;

                                label {
                                    font-size: 12px;
                                    line-height: 16px;
                                    text-transform: uppercase;
                                }

                                span {
                                    color: $primary;
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 24px;
                                }


                            }
                        }
                    }

                }

            }

        }
    }
}

.team-point {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }

    span {
        display: flex;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: #7B8599;
        color: #FFF;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
    }
}

.tree-user-profile {
    display: flex;
    align-items: center;
    gap: 10px;


    .photo {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        @include flexcenter;
        border-radius: 56px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #F0F2F7;
    }

    svg {
        width: 20px;
    }

    img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
    }


    .info {
        margin-left: 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;

        .status {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
        }

        h5 {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            margin: 0;
        }

        p {
            color: #525866;
            font-size: 14px;
            line-height: 16px;
            margin: 0;
        }

        >ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: flex;
                padding: 2px 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background: #7B8599;

                span,
                label {
                    color: #FFF;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 12px;
                }

                &:first-child {
                    background: $primary;
                }
            }
        }
    }
}

.tree-level-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        .level-content {
            cursor: pointer;
            width: 64px;
            height: 64px;
            padding: 10px;
            @include flexcenter;
            flex-direction: column;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFF;
            flex-shrink: 0;
            position: relative;

            &.active {
                border: 1px solid $primary;
                background: #FFF2F4;

                label {
                    color: #000;
                }

                span {
                    color: $primary;
                }
            }

            &.total-level {
                border: 1px solid $primary;
                background: #FFF2F4;

                label {
                    color: $primary;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                }

                span {
                    color: #000;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

            p {
                position: absolute;
                margin: 0;
                padding: 2px 8px;
                background: $white;
                border-radius: 20px;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 12px;
            }



            span {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-transform: uppercase;
                cursor: pointer;
            }

            label {
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                cursor: pointer;
            }
        }
    }

}

.tree-slider {
    width: 100%;

    .slick-list,
    .slick-track {
        height: 65px;
    }

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 8px;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-right: 15%;
        margin-bottom: 0;
        margin-left: 15%;
        list-style: none;

    }

    .slick-track {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .slick-next {
        right: -45px;
    }

    .slick-prev {
        left: -55px;
    }

    .slick-prev,
    .slick-next {
        width: 40px !important;
        height: 40px !important;
        border: 1px solid rgba(255, 255, 255, 0.45) !important;
        padding: 10px !important;
        transform: translate(0, -60%);
        color: $primary !important;
        border: 1px solid !important;
        padding: 5px;
        background: transparent !important;
        border-radius: 50% !important;
        stroke-width: 2px;
    }
}

.progress-wrap {
    margin-top: -4px;
}

[data-bs-theme="dark"] {
    .tree-user-profile {

        .photo {
            border: 1px solid rgba(255, 255, 255, 0.15);
            background: #17181a;
        }

        svg {
            fill: #ffffff;
        }

        .info {

            h5 {
                color: #ffffff;
            }

            p {
                color: #525866;
            }
        }
    }

    .tree-level-list {

        li {
            .level-content {

                border: 1px solid #ffffff33;
                background: #000000;

                &.total-level {
                    border: 1px solid $primary;
                    background: #FFF2F4;

                    label {
                        color: $primary;
                    }

                }

                span {
                    color: #525866;
                }
            }
        }
    }

    .network-list {
        ul {
            li {
                .tree-card-wrap {
                    background: #000000;
                    border: 1px solid rgba(255, 255, 255, 0.03);

                    .tree-card-end {
                        width: 33.33%;
                        display: flex;
                        justify-content: flex-end;

                        ul {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 16px;
                            list-style-type: none;

                            li {

                                .level {
                                    border: 1px solid rgba(255, 255, 255, 0.2);
                                    background: #000000;

                                    label {
                                        color: $primary;
                                    }

                                    span {
                                        color: #525866;

                                    }

                                }
                            }
                        }

                    }

                    .tree-card-center {
                        >ul {
                            li {
                                .tree-info {
                                    span {
                                        color: #ffffff;
                                    }
                                }
                            }
                        }

                    }
                }

            }
        }
    }

    .level {
        label {
            color: #525866;
        }
    }
}

@media screen and (max-width: 767px) {

    .tree-slider {

        .slick-track {
            display: flex;

            >div {
                width: 61px !important;
            }
        }

        .slick-prev,
        .slick-next {
            position: absolute;
            top: 100px;

        }

        .slick-prev {
            left: 35%;
        }

        .slick-next {
            right: 35%;
        }
    }


    .tree-level-list {
        margin-top: 20px;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: flex-end;
    }

    .network-list {

        ul {
            li {
                .tree-card-wrap {
                    display: block;
                    padding: 20px;

                    .tree-user-profile {
                        width: 80%;
                    }

                    .rank-img {
                        position: absolute;
                        right: 35px;
                    }

                    .tree-card-center {
                        width: 100%;
                        margin-top: 20px;

                        >ul {
                            flex-wrap: wrap;

                            li {
                                width: 100%;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 37px;
                            }
                        }
                    }

                    .counting-div {
                        width: 100%;

                        span {
                            padding: 0px 11px;
                            font-size: 12px;
                        }
                    }

                    .tree-card-end {
                        width: 100%;
                        display: flex;
                        justify-content: start;
                        margin-top: 20px;

                        ul {
                            width: 100%;

                            li {
                                &:last-child {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    gap: 10px;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    transform: translate(-15px, 15px);

                                    >img {
                                        width: 44px;
                                    }
                                }

                            }

                            .team-point {
                                width: 100%;

                                span {
                                    width: 40%;
                                    padding: 3px 16px;
                                    border-radius: 50px;
                                    font-size: 14px;
                                    font-weight: 400;
                                }

                                label {
                                    width: 10%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}