//colors
$primary: #FF1500;
$secondary: #1E1E24;
$green: #28a745;
$red: #dc3545;
$themeBlack: #121606;
$themeLight: #F3F6FD;
$themeDark: #253250;
$lightGray: #FAFAFA;
$warning: #FFCD4E;
$cyan: #4FC4C4;
$gray: #8D8D8D;
$black: #1F1F1F;

$orange: #E46226;

$light-background: #FFF;
$light-text: #1F2026;
$dark-background: #17181a;
$dark-text: rgba(255, 255, 255, 0.8);

$smgray: #D1D1D1;
$white: white;
// fonts

$font-primary: 'Plus Jakarta Sans', sans-serif;
$font-secondary: 'Urbanist', sans-serif;
$font-bebas: 'Plus Jakarta Sans', sans-serif;
$font-krub: 'Plus Jakarta Sans', sans-serif;
$font-number: 'Plus Jakarta Sans', sans-serif;
$font-number: 'Plus Jakarta Sans', sans-serif;
$poppin: 'Poppins', sans-serif;
$Playfair: 'Playfair Display', serif;
// general

$radius: .4rem;