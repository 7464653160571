.icon-box-circle {
    display: flex;
    align-items: center;

    .icon {
        width: 60px;

        span {
            width: 52px;
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $primary;

            i {
                color: #FFF;
                font-size: 2.1rem;
            }
        }
    }

    .info {
        padding-left: 1rem;

        h3 {
            font-size: 1rem;
            margin: 0;
            font-weight: 600;
        }

        p {
            margin: 0;
            font-size: .8rem;
        }

        h4 {
            font-weight: normal;
            color: rgba(0, 0, 0, 0.6);
            text-transform: uppercase;
            font-size: 16px;
        }

        h2 {
            margin: 0;
            font-size: 24px;
        }
    }
}

body {
    &.theme-light {
        .icon-box-circle {
            .icon {
                span {
                    @include themebg($light: true);
                }
            }
        }

        .icon-box {
            background-color: #E2F3F3;
        }
    }

    &.theme-dark {
        .icon-box-circle {
            .icon {
                span {
                    @include themebg($light: false);
                }
            }
        }

        .icon-box {
            background-color: $dark-background !important;
        }
    }
}


.icon-box {
    display: flex;
    align-items: center;

    padding: 1rem;
    border-left: 4px solid #4FC4C4;
    border-radius: 0 20px 20px 0;
    cursor: pointer;

    &.green1 {
        background-color: #DCF6EF;
        border-left-color: #42DCB1;

        .info {
            h4 {
                color: #42DCB1;
            }
        }
    }

    &.brown {
        background-color: #F5F3E9;
        border-left-color: #D2C383;

        .info {
            h4 {
                color: #D2C383;
            }
        }
    }

    &.green2 {
        background-color: #E5F6E0;
        border-left-color: #6CD74C;

        .info {
            h4 {
                color: #6CD74C;
            }
        }
    }

    .icon {
        width: 60px;

        img {
            width: 100%;
        }

        span {
            background: rgb(245, 226, 154);
            background: linear-gradient(90deg, rgba(245, 226, 154, 1) 0%, rgba(227, 207, 129, 1) 100%);
            width: 52px;
            height: 52px;
            border-radius: $radius;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.8rem;
            }

            img {
                width: 44px;
            }
        }
    }

    .info {
        padding-left: .6rem;

        h4 {
            margin: 0;
            font-size: 1.6rem;
            color: #4FC4C4;
            font-weight: bold;
        }

        p {
            margin: 0;
            color: #8E9F9F;
            font-size: 1rem;
            font-weight: 500;
        }
    }
}

.icon-box-style-2 {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 30px;

        p {
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
        }

        h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
        }

        img {
            width: 30px;
        }
    }
}

@media screen and (max-width : 767px) {
    .icon-box-style-2 {
        li {
            h3 {
                font-size: 16px;
            }

        }
    }

}