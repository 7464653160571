.landing-page {
	background-color: #000;
	color: rgba(255, 255, 255, .8);

	p {
		font-weight: 300;
	}
}

.terms-wrap h1 {
	font-size: 22px;
}

.video-item {
	text-align: center;
	max-width: 290px;
	margin: auto;
	cursor: pointer;

	img {
		max-width: 100%;
	}

	h4 {
		font-size: 1rem;
		margin-top: 1.5rem;
	}
}

.fb-section {
	h3 {
		font-size: 26px;
		text-transform: uppercase;
	}

	p {
		font-size: 1rem;
	}
}

.stats {
	padding: 2rem 0;
	background-color: #181818;

	.stat-item {
		color: #FFF;
		display: flex;
		align-items: center;
		justify-content: center;

		.count {
			font-size: 5rem;
			font-family: $font-bebas;
			margin-right: .6rem;

			span {
				color: $cyan;
			}
		}

		.stat-label {
			text-transform: uppercase;
			line-height: 1.6rem;
			font-size: 1.5rem;
		}
	}
}





.find-us-box {
	text-align: center;
	background-color: #FFF;
	padding: 2rem 1rem;
	border-radius: 10px;

	&.small {
		max-width: 300px;
		margin: auto;
	}

	&.bg-gold {
		@include GradientGolden();

		h3 {
			color: #FFF;
		}

		p {
			color: #FFF;
		}
	}

	h3 {
		text-transform: uppercase;
		font-weight: bold;
		color: $primary;
		font-size: 1.3rem;
	}

	p {
		color: #bab3b3;
		font-size: .9rem;
		font-weight: normal;
		max-width: 300px;
		margin: auto;
	}
}

.leader-item {
	text-align: center;
	margin-top: 2rem;

	img {
		max-width: 90%;
		margin-bottom: 1rem;
	}

	h3 {
		text-transform: uppercase;
		font-size: 1.4rem;
	}
}


.cta {
	padding: 4rem;
	border-radius: 20px;
	background: rgb(91, 82, 52);
	background: linear-gradient(90deg, rgba(91, 82, 52, 1) 0%, rgba(36, 35, 33, 0) 63%);
	position: relative;
	overflow: hidden;

	h2 {
		text-transform: uppercase;
		font-size: 30px;
	}

	p.home-detail {
		text-align: justify;
		font-size: 14px;
	}

	>img {
		position: absolute;
		right: -30px;
		top: -30px;
		width: 400px;
	}
}


.cta2 {
	padding: 4rem;
	border-radius: 20px;
	background: rgb(36, 35, 33);
	background: linear-gradient(90deg, rgba(36, 35, 33, 0) 22%, rgba(91, 82, 52, 1) 100%);
	position: relative;
	overflow: hidden;

	h2 {
		text-transform: uppercase;
		font-size: 2rem;
	}

	p {
		font-size: 15px;
		margin-top: 13px;
	}

	>img {
		position: absolute;
		left: -30px;
		top: -30px;
		width: 500px;
	}
}

.container.terms-wrap {
	font-size: 14px;

	h2 {
		font-size: 18px;
	}

	p {
		text-align: justify;
	}
}



.rank-ist {
	text-align: center;

	img {
		width: 150px !important;
		margin: auto;
	}

	h4 {
		margin-top: 20px;
		font-size: 17px;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.subscribe-form {
	max-width: 350px;
	margin: 1rem 0;

	.input-subscribe {
		width: 100%;
		background-color: transparent;
		height: 50px;
		text-align: center;
		border: none;
		border-bottom: 1px solid #FFF;
		color: #FFF;

		&:focus {
			outline: none;
			border-bottom-color: $primary;
		}

		&::placeholder {
			color: rgba(255, 255, 255, .5);
		}
	}

	button {
		display: block;
		width: 100%;
		margin-top: 1rem;
		background: linear-gradient(89.93deg, #D6BB6E 0.83%, #D5B972 47.33%, #FBF6BB 101.6%);
		border-radius: 15px;
		color: #000;
		font-weight: 500;
	}
}

.capitals-list {
	margin: 30px 0 0 0;
	padding: 0;
	list-style-type: none;

	li {
		margin-top: 1rem;
		font-size: 1.2rem;

		strong {
			display: block;
			color: #FFF;
			font-weight: 500;
		}

		p {
			font-size: 16px;
		}
	}
}

.testimonials {
	padding: 3rem 0;

	.testimonial-item {
		margin: 3rem 0;
		text-align: center;

		.testi-user {
			margin-top: 1rem;

			h4 {
				color: $primary;
				margin: 0;
			}

			p {
				margin: 0;
				color: #FFF;
			}
		}

		p {
			margin-top: 1rem;
			color: #77838f;
		}
	}
}

.leader-slider {
	display: none;
}

.news-slider {
	display: none;
}

.revolution {
	display: block;
	margin-left: 0;
	margin-top: 0px;
	// font-size: 1.1rem;
}

#timer {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 999;
	background-color: rgba(0, 0, 0, .6);

	>img {
		width: 140px;
		margin-bottom: 30px;
	}

	h3 {
		color: #FFF;
	}
}

#launch-time {
	display: flex;
	justify-content: center;
	margin-top: 40px;

	#clockdiv {
		display: flex;
		justify-content: center;

		>div {
			text-align: center;
			display: flex;
			position: relative;
			margin: 0 20px;
			flex-direction: column-reverse;

			&::after {
				content: ":";
				color: $primary;
				position: absolute;
				right: -25px;
				top: 18%;
				font-size: 40px;
				font-weight: bold;
			}

			&:last-child {
				&::after {
					content: "";
				}
			}

			// &:first-child {
			// 	display: none;
			// }
			span {
				font-size: 50px;
				border-radius: 4px;
				font-weight: bold;
				width: 88px;
				height: 100px;
				// @include gradientDark();
				// color: #FFF;
				// border: 3px solid $primary;
				background-image: url('../images/timebg.svg');
				background-size: cover;
				background-repeat: no-repeat;
				color: $primary;
				margin-bottom: 6px;
				padding: 16px 0 0 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.revolution {
		display: inline-block;
		margin-left: 5px;
		margin-top: 0px;
		
	}
	.rank-ist {

		img {
			width: 160px !important;
		}
	}

	.globe {
		max-width: 80%;
	}

	#education {
		.col-md-4 {
			order: 2;
		}

		.col-md-6 {
			order: 1;
		}
	}

	.video-item {
		margin: 0 auto 60px auto;
	}

	#timer h3 {
		font-size: 18px;
	}

	#launch-time {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		#clockdiv {
			display: flex;
			justify-content: center;

			>div {

				font-size: 12px;
				margin: 0 5px;
				min-width: 70px;

				&::after {
					display: none;
				}

				span {
					font-size: 30px;
					width: 64px;
					height: 73px;

				}
			}

		}
	}


	.news-slider {
		display: block;
	}

	.leader-desktop {
		display: none;
	}

	.desktop-news {
		display: none;
	}

	.leader-slider {
		display: block;
	}

	.capitals-list {
		li {
			p {
				font-size: 14px;
				text-align: justify;

				strong {
					font-size: 400 !important;
				}
			}
		}
	}

	.leader-item {
		img {
			max-width: 69%;
		}
	}

	.cta {
		padding: 2rem;

		.row {
			padding-top: 215px;

			h2 {
				font-size: 1.1rem;
				font-weight: 600;
			}
		}

		h2 {
			font-size: 1.1rem;
			font-weight: 600;
		}
	}

	.cta2 {
		padding: 2rem;

		.row {
			padding-top: 25px;

			h2 {
				font-size: 1.1rem;
				font-weight: 600;
			}
		}

		h2 {
			font-size: 1.1rem;
			font-weight: 600;
		}
	}

	.landing-page {
		p {
			font-size: 14px !important;
			text-align: justify;
		}
	}

	.fb-section {
		h3 {
			font-size: 1.1rem;
			font-weight: 600;
		}

		p {
			font-size: 14px !important;
			text-align: justify;
		}
	}

	.ceo {
		display: block;
		margin-top: -18px;
	}

	.ceo-profile {
		max-width: 90%;
	}

	.home-detail {
		font-size: 13px;
		text-align: justify;
	}

	.icon-bullet {
		font-size: 13px;
		text-align: justify;
	}

	.find-us-box {
		margin-bottom: 1rem;

		&.small {
			max-width: 100%;
			margin-bottom: 1rem;
		}
	}

	.section-trade {
		padding: 0;
	}

	.section-about {
		padding: 0;
	}

	.section-title {
		font-size: 1.1rem;
		text-align: center;
		span{
			font-size: 1.1rem;
		}
	}

	.challanges {
		.challanges-wrap {
			min-height: auto;

			h2 {
				font-size: 2.4rem;
			}
		}
	}

	.section-trade {
		.asset-box {
			margin-bottom: 140px;
		}
	}

	.features-section {
		min-height: auto;
		display: none;
	}

	.section-tutorials {
		.tutorial-box {
			margin-bottom: 2rem;
		}
	}

	.slick-initialized .slick-slide {
		margin: auto;
		text-align: center;
	}
}