.profile-section {
    >.heading {
        margin-bottom: 24px;
    }

    svg {
        width: 20px;
    }

    .profile {
        display: flex;
        align-items: center;
        gap: 20px;

        .image {
            width: 96px;
            height: 96px;
            border-radius: 50%;
            @include flexcenter;
            background: #F0F2F7;
            flex-shrink: 0;

            &:hover {
                opacity: .8;
            }

            img {
                width: 96px;
                height: 96px;
                border-radius: 50%;
            }

            svg {
                path {
                    fill: #525866;
                }
            }
        }

        .content {
            width: 100%;

            .info {
                h1 {
                    font-size: 20px;
                    font-weight: 500;
                    margin: 0;
                }

                p {
                    font-size: 12px;
                    margin: 0;
                }
            }

            >ul {
                display: flex;
                list-style-type: none;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                width: 100%;
                padding: 0;
                margin: 0;
                margin-top: 10px;

                li {

                    &:first-child {
                        img {
                            width: 20px;
                        }
                    }

                    &:nth-child(2) {
                        p {
                            padding: 4px 12px;
                            border-radius: 12px 0px 12px 12px;
                            background: #E6E9F2;
                            margin: 0;
                        }
                    }

                    &:last-child {
                        display: flex;
                        gap: 10px;

                        p {
                            margin: 0;
                            font-weight: 500;
                        }
                    }
                }

            }
        }
    }

    .link {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        width: 100%;

        >span {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            width: 80px;
        }

        >div {
            display: flex;
            padding: 4px 12px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 24px;
            border: 1px dashed rgba(0, 0, 0, 0.15);
            width: calc(100% - 90px);

            span {
                overflow: hidden;
                color: #7A8599;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 12px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                width: calc(100% - 23px);
            }

            svg {
                z-index: 2;
                cursor: pointer;
            }
        }
    }

    .info-icon-list {
        border: 1px solid rgba(0, 0, 0, 0.10);
        padding: 20px;
        border-radius: 16px;
        width: 100%;
        height: 176px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                color: #525866;
                font-size: 14px;
                font-weight: 600;
            }

            .icon {
                @include flexcenter;
                width: 48px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 16px;

                &.pink {
                    background: rgba(255, 64, 128, 0.05);

                    svg {
                        stroke: rgb(255, 64, 128);
                    }
                }

                &.orange {
                    background: rgba(255, 96, 64, 0.05);

                    svg {
                        stroke: rgb(255, 96, 64);
                    }
                }

                &.red {
                    background: rgba(255, 0, 43, 0.05);

                    svg {
                        stroke: rgb(255, 0, 43);
                    }
                }
            }
        }

        >ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 16px;


                span {
                    color: #525866;
                    font-size: 14px;
                }

                label {
                    font-size: 16px;
                }
            }
        }

        .rank-content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .active-rank {
                display: flex;
                gap: 20px;
                align-items: center;

                .rank-icon {
                    width: 35px;

                    img {
                        width: 37.364px;
                    }
                }

                .rank-info {
                    width: 100%;

                    span {
                        color: #525866;
                        font-size: 14px;
                    }

                    h6 {
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 600;
                        margin: 0;
                    }
                }
            }

            .pending-rank {
                display: flex;
                gap: 20px;
                align-items: flex-start;
                position: relative;
                flex-shrink: 0;

                .rank-icon {
                    width: 51px;

                    img {
                        opacity: 0.2;
                        width: 30.419px;
                    }
                }

                .rank-info {
                    width: 100%;

                    span {
                        color: #525866;
                        font-size: 14px;
                    }

                    h6 {
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 600;
                        margin: 0;
                    }
                }

                .task {
                    position: absolute;
                    right: -11px;
                    top: 0;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 13px;
                        margin-left: 6px;
                    }

                    .rank-progress {
                        border: 1px solid rgba(0, 0, 0, 0.15);
                        background-color: #FFF;
                        border-radius: 80px;
                        height: 10px;
                        width: 50px;
                        overflow: hidden;

                        >div {
                            height: 9px;
                            background: linear-gradient(90deg, #2B65D9 0%, #2BBCD9 100%);
                        }
                    }
                }
            }
        }
    }

    .profile-card-list {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 20px;
        margin: 0;
        padding: 0;

        >li {
            width: 33.33%;
        }
    }
}

.dash-list {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    list-style-type: none;
    padding: 0;

    li {
        width: 46%;
    }
}

.dash-card {
    padding: 9px 16px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        flex-shrink: 0;
        @include flexcenter;
    }

    svg {
        width: 32px;
    }

    p {
        margin: 0;
        font-size: 14px;
        color: #525866;
        font-size: 18px;
        margin-bottom: 10px;
    }

    h3 {
        font-weight: bold;
        color: #000;
        font-size: 18px;
        font-weight: 500;
    }

    h3,
    p {
        margin: 0;
    }

    &.yellow {
        .icon {
            background: rgba(255, 143, 64, 0.05);

            svg {
                stroke: rgb(255, 143, 64);
            }
        }
    }

    &.orange {
        .icon {
            background: rgba(255, 96, 64, 0.05);

            svg {
                stroke: rgb(255, 96, 64);
            }
        }
    }

    &.red {
        .icon {
            background: rgba(255, 0, 43, 0.05);

            svg {
                stroke: rgb(255, 0, 43);
            }
        }

    }

    &.pink {
        .icon {
            background: rgba(255, 64, 128, 0.05);

            svg {
                stroke: rgb(255, 64, 128);
            }
        }

    }

}

.heading {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.bs-promo-section {
    .heading {
        margin-bottom: 20px;
    }
}

.team-list {
    list-style-type: none;
    padding: 0;

    li {
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 17px 0;
        justify-content: flex-start;

        a {
            border: none;
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap: 16px;
            padding: 0;
        }

        h5 {
            color: #525866;
            margin: 0;
        }

        p {
            color: #000;
        }

        .icon {
            border-radius: 16px;
        }

        &:last-child {
            border: none;
            padding-bottom: 0;
        }
    }
}

.else-emoji {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    svg {
        width: 40px;
        stroke: #c5c5c5;
        margin-bottom: 10px;
    }

    h4 {
        font-size: 14px;
        margin: 0;
    }
}

.dash-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;



    .menu {
        display: flex;
        align-items: center;
        gap: 12px;

        .menu-link {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            height: 33px;
            overflow: hidden;
            border-radius: 25px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin: 0;

            li {
                position: relative;

                a {
                    @include flexcenter;
                    border-radius: 50px;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 10px 15px;
                    color: #000;
                    cursor: pointer;

                    &.active {
                        background: rgba(255, 0, 43, 0.05);
                        color: $primary;
                    }

                }

                .menu-date {
                    visibility: hidden;
                    width: 0;
                    max-width: 0;
                    min-width: 0;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    cursor: pointer;
                }

                >button {
                    background: transparent;
                    border: none;
                    padding: 10px 15px;
                    padding-right: 10px;
                }

                svg {
                    width: 20px;
                }
            }
        }

        .menu-icon {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            height: 33px;
            overflow: hidden;
            border-radius: 25px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin: 0;

            li {
                position: relative;

                a {
                    padding: 10px 15px;
                    border-radius: 50px;
                    font-size: 12px;
                    font-weight: 600;
                    @include flexcenter;
                    color: #000;
                    cursor: pointer;

                    &.active {
                        background: rgba(255, 0, 43, 0.05);
                        color: $primary;
                    }
                }

                svg {
                    width: 20px;
                }
            }
        }
    }
}

.dash-bg-card {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;

}

.pool-card {

    >ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        list-style-type: none;
        position: relative;
        gap: 8px;

        >li {
            display: flex;
            gap: 50px;

            .info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                padding: 16px 56px 16px 16px;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                position: relative;
                height: 90px;
                width: 50%;
                cursor: pointer;

                label {
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                }

                h1 {
                    text-align: center;
                    font-size: 24px;
                    font-style: italic;
                    font-weight: 500;
                    margin: 0;
                    font-family: $Playfair;
                }

                .icon {
                    position: absolute;
                    right: -3px;
                    top: -3px;
                    display: flex;
                    padding: 8px;
                    align-items: flex-start;
                    gap: 8px;
                    border-radius: 24px;

                    svg {
                        width: 16px;
                        height: 16px;

                        path {
                            fill: $white;
                        }
                    }

                    &.open {
                        border: 2px solid rgb(187 187 187);
                        background: $primary;

                    }

                    &.close {
                        background-color: rgba(123, 133, 153, 1);
                    }
                }

                &.active {
                    background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), #FFF7F9;

                    &::after {
                        content: '';
                        position: absolute;
                        right: -12px;
                        bottom: 37%;
                        height: 24px;
                        width: 24px;
                        background: #FFF7F9;
                        border: 1px solid rgba(0, 0, 0, 0.10);
                        border-top: none;
                        border-right: none;
                        transform: rotate(225deg);
                    }
                }


            }

            .detail {
                display: none;
                width: 280px;
                min-width: 232px;
                padding: 0px 16px 16px 16px;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                border-radius: 16px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), #FFF7F9;
                box-shadow: 0px 4px 16px 0px rgba(100, 100, 111, 0.10);
                position: absolute;
                right: 0;
                top: 0;
                transition: all 0.5s ease;

                >svg {
                    position: relative;
                    z-index: 1;
                }

                .head {
                    display: flex;
                    width: 276px;
                    min-width: 276px;
                    padding: 16px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 16px;
                    opacity: 0.9;
                    background: #FFF;
                    box-shadow: 0px 4px 16px 0px rgba(100, 100, 111, 0.1);
                    text-align: center;
                    margin-top: -35px;

                    image {
                        width: 248px;
                        height: 160px;
                        flex-shrink: 0;

                        img {
                            width: 100%;
                        }
                    }

                    h1 {
                        font-size: 18px;
                        font-weight: 600;
                        text-align: center;
                    }
                }

                .content-body {
                    width: 100%;

                    h6 {
                        font-weight: 700;
                        margin-bottom: 20px;
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        list-style-type: none;
                        padding: 0;
                        row-gap: 15px;

                        li {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            label {
                                color: #000;
                                font-family: Poppins;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                            span {
                                color: #FF002B;
                                font-family: Poppins;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }
                    }
                }
            }

            &.open {
                .detail {
                    display: flex;
                    z-index: 2;
                }
            }

            &.active {
                .detail {
                    display: flex;
                    z-index: 2;
                }
            }

            &.close {
                .detail {
                    display: none;
                }
            }

            &.first-pool {
                .detail {
                    display: flex;
                }
            }

            &:hover {
                .detail {
                    display: flex;
                    z-index: 3;
                }
            }
        }

    }
}

.dashboard_wallets {
    padding: 26px 14px;
    min-height: 506px;

    >h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .thumnail {
        position: relative;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 253px;
        margin: 20px 0px 24px 0;
        background-image: url('../images/partner/thumnail.svg');
        background-position-x: 115%;
        background-position-y: -1px;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 20px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;

            li {
                h3 {
                    font-size: 18px;
                    margin: 0;
                }

                span {
                    color: #525866;
                    font-size: 14px;
                }
            }
        }

        img {
            width: 54px;
        }

        >svg {
            width: 50px;
        }

    }
}

.earning-card {
    min-height: 506px;
}

.list_wallets {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 27px;

    li {
        width: 100%;

        h5,
        p {
            margin: 0;
        }
    }
}

.bar-background {
    background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%);
    border-radius: 16px;
}

.rank-card {
    border-radius: 10px;

    background: rgba(237, 244, 250, 0.45);
    padding: 32px;

    h3 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .rank {
        display: flex;
        align-items: center;

        img {
            width: 44px;
            margin-right: 10px;
        }

        h4 {
            margin: 0;
            font-size: 20px;
            font-weight: bold;
            color: #385CBC;
        }
    }

    ul {
        margin: 20px 0 0 0;
        padding: 0;
        list-style-type: none;

        li {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;

            &.divider {
                background-color: rgba(0, 0, 0, 0.2);
                height: 1px;
                padding: 0;
                margin: 20px 0;

            }
        }
    }
}

.royality-income {
    background-color: #DFECF2;
    padding: 30px;
    border-radius: 10px;

    ul {
        margin: 20px 0;
        font-size: 18px;
    }
}

.level-graph {
    position: relative;
    display: flex;
    align-items: center;

    >ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        list-style-type: none;
        gap: 2px;
        padding: 0;
        width: 85%;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0px 16px 4px 8px;
            gap: 8px;
            border-radius: 20px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFF;

            label {
                font-size: 13px;
                font-weight: 600;
                line-height: 16px;
            }

            &.active {
                background: #FFF8F6;

                svg {
                    width: 20px;

                    path {
                        fill: $primary;
                    }
                }
            }

            svg {
                width: 20px;

                path {
                    fill: rgba(123, 133, 153, 1);
                }
            }
        }
    }

    .ellipse {
        background: #FFF9F5;
        border: 1px solid rgba(0, 0, 0, 0.15);
        width: 224px;
        height: 224px;
        flex-shrink: 0;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        @include flexcenter;

        .inner-ellipse {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background: #FFF;
            filter: drop-shadow(0px 4px 16px rgba(100, 100, 111, 0.1));
            @include flexcenter;
            flex-direction: column;
        }

        h1 {
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            margin: 0;
            margin-bottom: 5px;
        }

        p {
            font-size: 12px;
            line-height: 16px;
            margin: 0;
        }
    }

}

.list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 9px;
    margin: 0;
    margin-top: 31px;
}

.primary-heading {
    color: #385CBC;
    position: relative;
    font-size: 2rem;
    font-weight: 500;

    &::after {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        background: #385CBC;
        width: 20px;
        height: 3px;
        border-radius: 5px;
    }
}


.tick-icon-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 0;
    padding: 0;

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span,
        i {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            @include flexcenter;
            background-color: #385CBC;
            color: $white;
            margin-right: 10px;
            font-size: 1rem;
        }
    }


}

[data-bs-theme="dark"] {
    #menubutton {
        .line {
            stroke: $primary;
        }
    }

    .team-list {

        li {
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);

            h5 {
                color: #ffffff;
            }
        }
    }

    .profile-section {
        .profile {
            .image {
                background: #17181a;

                svg {
                    path {
                        fill: #000000;
                    }
                }
            }

            .content {

                >ul {

                    li {
                        &:nth-child(2) {
                            p {
                                background: #17181a;
                            }
                        }
                    }

                }
            }
        }

        .link {

            >div {
                border: 1px dashed rgba(255, 255, 255, 0.566);
            }
        }

        .info-icon-list {
            border: 1px solid rgba(255, 255, 255, 0.15);
        }
    }

    .dash-bg-card {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #000000;
        box-shadow: rgb(255, 255, 255)0px 1px 0px rgba(255, 255, 255, 0.1), 0px 0px 5px 0px rgba(255, 255, 255, 0.1);
    }

    .dash-card {
        border: 1px solid rgba(255, 255, 255, 0.15);

        p {
            color: #c3c3c3;
        }

        h3 {
            color: #ffffff;
        }
    }

    .bar-background {
        background: #17181a;
    }

    .dashboard_wallets {
        .thumnail {
            background: #17181a;
            margin: 20px 0px 24px 0;
            background-image: url('../images/partner/thumnail_dark.svg');
            background-position-x: 115%;
            background-position-y: -1px;
            background-repeat: no-repeat;
            background-size: contain;

            img {
                width: 60px;
                background: white;
                padding: 3px;
                border-radius: 10px;
            }
        }
    }

    .dash-header {

        .menu {
            .menu-link {

                border: 1px solid rgba(255, 255, 255, 0.10);

                li {

                    a {
                        color: #525866;

                        &.active {
                            color: $primary;
                            background: rgb(255 26 64 / 23%);
                        }
                    }

                    button {
                        svg {
                            stroke: #525866;
                        }
                    }
                }
            }

            .menu-icon {
                border: 1px solid rgba(255, 255, 255, 0.1);

                li {
                    a {
                        color: #525866;

                        &.active {
                            color: $primary;
                            background: rgb(255 26 64 / 23%);
                        }
                    }
                }
            }
        }
    }

    .level-graph {

        >ul {
            li {
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #141414;
                ;

                &.active {
                    background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), #141414;
                    background-blend-mode: color, normal;
                }
            }
        }

        .ellipse {
            background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), #141414;
            background-blend-mode: color, normal;
            border: 1px solid rgba(0, 0, 0, 0.15);

            .inner-ellipse {
                background: #000000;
                filter: drop-shadow(0px 4px 16px rgba(255, 255, 255, 0.1));
            }
        }

    }

    .pool-card {
        >ul {
            >li {
                .info {
                    border: 1px solid #373737;

                    &.active {
                        border: 1px solid #373737;
                        background: #1c0301;

                        &::after {
                            background: #1c0301;
                            border: 1px solid #373737;
                            border-top: none;
                            border-right: none;

                        }
                    }
                }

                .detail {

                    border: 1px solid rgba(0, 0, 0, 0.15);
                    background: #17181a;
                    box-shadow: 0px 4px 16px 0px rgba(100, 100, 111, 0.10);


                    .head {
                        background: #FFF;
                        box-shadow: 0px 4px 16px 0px rgba(100, 100, 111, 0.1);
                    }

                    .content-body {
                        ul {
                            li {

                                label {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}

.promo-slider {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        filter: invert(1);
    }
}

@media screen and (max-width:767px) {
    .profile-section {
        .link {
            flex-direction: column;

            >div {
                width: 100%;
            }
        }

        .profile-card-list {
            flex-direction: column;

            >li {
                width: 100%;
            }
        }
    }

    .earning-card {
        min-height: auto;
    }

    .dash-list {
        li {
            width: 100%;
        }
    }

    .level-graph {
        margin-top: 120px;

        >ul {
            flex-direction: row;
            justify-content: center;
            width: 100%;

            li {
                text-align: center;
                font-size: 30px;
                padding: 35px 11px 10px 10px;
                writing-mode: vertical-lr;
                text-orientation: mixed;
                margin: 0;
                letter-spacing: 6px;
                transform: rotate(180deg);
                height: 330px;
                width: 30px;
                position: relative;

                span {
                    transform: rotate(180deg);
                    position: absolute;
                    top: 6px;
                    left: 4px;
                }
            }
        }

        .ellipse {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }

    }

    .dash-header {
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 15px;

        .menu {
            .menu-link {
                li {
                    display: none;

                    &:first-child,
                    &:nth-child(2) {
                        display: block;
                    }
                }
            }
        }
    }

    .pool-card {

        >ul {
            padding: 0;

            >li {
                background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), #FFF7F9;
                padding: 16px;
                border-radius: 16px;
                justify-content: center;
                align-items: center;
                gap: 13px;
                flex-direction: column;

                .info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    padding: 16px 56px 16px 16px;
                    border-radius: 12px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    position: relative;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;



                    .icon {
                        position: absolute;
                        right: -3px;
                        top: -3px;
                        display: flex;
                        padding: 8px;
                        align-items: flex-start;
                        gap: 8px;
                        border-radius: 24px;

                        svg {
                            width: 16px;
                            height: 16px;

                            path {
                                fill: $white;
                            }
                        }

                        &.open {
                            border: 2px solid rgb(187 187 187);
                            background: $primary;

                        }

                        &.close {
                            background-color: rgba(123, 133, 153, 1);
                        }
                    }

                    &.active {

                        border: none;

                        &::after {
                            display: none;
                        }
                    }


                }

                .detail {
                    position: relative;
                    display: none;
                    padding: 0;

                    .head {
                        margin-top: 0;
                    }

                    .content-body {
                        padding: 20px;

                        ul {
                            row-gap: 10px;
                        }
                    }

                    >svg {
                        position: absolute;
                        left: 50%;
                        right: 50%;
                        transform: translate(-50%, 0%);
                    }

                }

                &.first-pool {
                    .detail {
                        display: flex;
                    }
                }

                &.open {
                    .detail {
                        display: flex;
                        z-index: 2;
                    }
                }

                &.active {
                    .detail {
                        display: flex;
                        z-index: 2;
                    }
                }

                &.close {
                    .detail {
                        display: none;
                    }
                }

                &:hover {
                    .detail {
                        display: flex;
                        z-index: 3;
                    }
                }


            }

        }
    }

    [data-bs-theme="dark"] {
        .pool-card {
            >ul {
                >li {
                    border: 1px solid rgba(255, 255, 255, 0.15);
                    background: transparent;

                    .info {
                        border: 1px solid #373737;
                    }
                }

            }
        }
    }
}