.kyc-section {
    svg {
        width: 20px;
    }

    .kyc-info-list {
        display: flex;
        align-items: center;
        list-style-type: none;
        gap: 10px;

        b {
            font-size: 16px;
            font-weight: 600;
        }

        span {
            font-size: 16px;
        }

        p {
            margin: 0;
            font-size: 14px;
            color: #999999;
        }
    }

    .examle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        label {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        span {
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
        }
    }


}

.kyc-upload {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;

    svg {
        width: 20px;
    }

    li {
        display: flex;
        flex-direction: column;
    }
}

.kyc-img-card {
    border-radius: 12px;
    border: 2px dashed rgba(255, 0, 43, 0.50);
    padding: 0;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 402px;
    svg {
        width: 20px;
    }

    &.franchise-kyc-card {
        width: 100%;
    }

    .upload-box {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(45, 45, 45);
        padding: 0;
        flex-direction: column;


        input {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .thumnail {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            svg {
                margin-bottom: 10px;
            }

            p {
                margin: 0;
            }
        }
    }

    &.white {
        background: #FFF;
        min-height: 220px;
    }

    &.pink {
        min-height: 456px;
        background: linear-gradient(180deg, #FFF9F5 0%, #FFF5F9 100%), linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
    }

    &.blue {
        min-height: 220px;
        background: linear-gradient(180deg, #F8FAFF 0%, #F0F2F7 100%), #FFF;
    }

    .delete {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
        display: inline-flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        bottom: 10px;
        left: 15px;

        svg {
            stroke: $primary;
        }
    }

    &.cursor_none {
        cursor: not-allowed;

        .thumnail,
        p {
            cursor: not-allowed;
        }
    }
}

.example-model {
    img {
        width: 100%;
    }
}

.kyc-submitted {

    font-family: $poppin;

    .pink-bg {
        display: flex;
        width: 100%;
        padding: 16px 24px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        background: #FFF2F4;
        text-align: center;

        h5 {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            margin: 0;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
        }
    }

    .verification {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        background: #FFF2F4;
        padding: 8px 16px;

        .content {
            h4 {
                color: $primary;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                margin: 0;
            }

            span {
                color: #525866;
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    .accordion-section {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding-bottom: 10px;
    }

}

.kyc-user-info {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    li {
        width: 48%;
        position: relative;

        span {
            color: #525866;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }

        h5 {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            margin: 0;
        }

        input {
            border: none;
            border-bottom: 1px solid #bababa;
            display: block;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            border-radius: 0;
            width: 85%;
            padding: 0;

            &:focus-visible,
            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
            }

            &:disabled {
                background: transparent;
            }

            &::placeholder {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        >svg {
            width: 20px;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;

            &:hover {
                color: $primary;
            }
        }

        >button {
            position: absolute;
            bottom: 0;
            right: 0;
            border-radius: 10px;
            padding: 10px 20px;

            svg {
                width: 20px;
            }
        }

        &:last-child {
            width: 100%;
        }
    }


}

.border-free-accordion {
    .accordion-item {
        border: none;
        background-color: transparent;

        .accordion-header {

            .accordion-button {
                background: transparent;
                background: transparent;
                padding: 10px 0;
                color: #000000;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;

                &:focus {
                    box-shadow: none;
                }

                &::after {
                    width: 16px;
                    height: 16px;
                    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                }
            }

            .accordion-button:not(.collapsed)::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
            }
        }

        .accordion-body {
            background-color: transparent;
            border: none;
            padding: 20px 0;
        }
    }
}

.passport-image {
    img {
        width: 100%;
    }
}

[data-bs-theme="dark"] {
    .kyc-section {
        svg {
            width: 20px;
        }

        .kyc-img-card {

            .upload-box {
                color: #17181a;

                .thumnail {
                    p {
                        color: $white;
                    }

                    svg {
                        stroke: $white;
                    }
                }
            }

            &.white {
                background: #17181a;
            }

            &.pink {
                background: linear-gradient(180deg, #FFF9F5 0%, #f8dce7 100%), #141414;
                background-blend-mode: color, normal;
            }

            &.blue {
                background: #000;
            }

            .delete {
                border: 1px solid rgba(255, 255, 255, 0.15);
                background: #000000;
            }
        }
    }

    .kyc-submitted {

        font-family: $poppin;

        .pink-bg {
            background: #17181a;

            h5 {
                color: $primary;
            }

            p {
                color: #525866;
            }
        }

        .verification {
            background: #17181a;

            .content {
                h4 {
                    color: $primary;
                }

                span {
                    color: #525866;
                }
            }
        }

        .opportunity-icon-list {
            p {
                color: #525866;
            }
        }

        .accordion-section {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);

            .accordion-item {
                .accordion-header {

                    .accordion-button {
                        color: #ffffff;

                        &::after {
                            width: 16px;
                            height: 16px;
                            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e") center/contain no-repeat;

                        }
                    }

                    .accordion-button:not(.collapsed)::after {
                        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /%3e%3c/svg%3e") center/contain no-repeat;
                    }
                }
            }
        }

        .kyc-user-info {
            li {

                span {
                    color: #525866;
                }

                h5 {
                    color: #ffffff;
                }

                input {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.50);
                    color: #ffffff;
                    background: transparent;

                    &::placeholder {
                        color: #ffffff;
                    }
                }

                svg {
                    color: $white;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .border-free-accordion {
        .accordion-item {

            .accordion-header {

                .accordion-button {
                    color: #ffffff;

                    &::after {
                        width: 16px;
                        height: 16px;
                        background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FF0000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                    }
                }

                .accordion-button:not(.collapsed)::after {
                    background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FF0000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");

                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .kyc-section {
        svg {
            width: 20px;
        }

        .kyc-info-list {
            flex-direction: column;
            align-items: flex-start;

            p {
                font-size: 12px;
            }
        }


        .kyc-upload {
            flex-direction: column;
            align-items: center;
        }

        .kyc-img-card {
            width: 310px;
        }
    }

    .kyc-submitted {
        .pink-bg {
            gap: 5px;

            h5 {
                font-size: 18px;
                font-weight: 600;
                line-height: normal;
            }

            p {
                font-size: 13px;
                line-height: normal;
            }
        }

        .verification {
            .content {
                h4 {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 17px;
                }
            }
        }

        .kyc-user-info {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            flex-shrink: 0;
            justify-content: space-between;
            align-items: flex-start;
            gap: 10px;

            li {
                width: 48%;

                span {
                    color: #525866;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                }

                h5 {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    margin: 0;
                }

                &:nth-last-child(2),
                &:nth-last-child(3) {
                    width: 100%;
                    border-bottom: 1px solid #dfdfdf;
                    padding-bottom: 10px;
                }

                >button {
                    padding: 7px;

                }

                &:last-child {
                    width: 100%;
                }
            }


        }

        .front,
        .back {
            width: 100%;
        }

    }

}