.form-control.is-valid,
.was-validated .form-control:valid {
    background-image: none;
}


.was-validated .form-select:valid:not([multiple]):not([size]) {
    background-image: none;
}


.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-position: right calc(0.375em + 3.1875rem) center;
}

.iconinput {
    position: absolute;
    right: 17px;
    top: 42px;

    &.top32 {
        top: 40px;
    }

    svg {
        width: 12px;

        path {
            fill: gray;
        }
    }

    &.Usernameindicater {
        top: 37px;
    }

    &.emailcross {
        top: 37px;
    }

    &.emailtick {
        top: 37px;
    }

    &.Referrraltick {
        top: 42px;
    }

    &.Referrralcross {
        top: 42px;
    }
}

.form-control {
    &::placeholder {
        font-size: 13px;
    }
}


input,
select {
    &:focus {
        box-shadow: none !important;
    }
}

select {
    option {}
}

div.form-control {
    min-height: 40px;
}

.form_default {

    .form-group {
        position: relative;

        label {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 8px;
            margin-bottom: .3rem;
        }

        .inputwrap {
            border-radius: 10px;
            padding: 2px;
            position: relative;
            border: $primary;

            input,
            textarea {
                display: flex;
                width: 100%;
                padding: 16px;
                justify-content: space-between;
                align-items: center;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                padding-right: 41px;

                &.disabled {
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    background: rgba(0, 0, 0, 0.05);
                }

                &:focus-visible,
                &:focus,
                &:active {
                    border: 1px solid $primary;
                    outline: $primary;
                    box-shadow: none;
                }

                &::placeholder {
                    color: rgba(0, 0, 0, 0.6) !important;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
            }

            input {
                height: 56px;
                text-overflow: ellipsis;
            }

            input[type="date"] {
                padding-right: 16px;
                display: block;
                width: 100%;
            }

            .form-select {
                display: flex;
                width: 100%;
                padding: 16px;
                justify-content: space-between;
                align-items: center;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                height: 56px;
                color: rgba(0, 0, 0, 0.6) !important;
                padding-right: 41px;

                &:focus-visible,
                &:focus,
                &:active {
                    border: 1px solid $primary;
                    outline: $primary;
                }

                &::placeholder {
                    color: rgba(0, 0, 0, 0.6);
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
            }

            .icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 16px;

                svg {
                    width: 20px;
                    cursor: pointer;
                }
            }

            // .form-control,
            // .form-select {
            //     border: 1px solid $primary;
            //     border-radius: 8px;
            //     background-color: $white;
            //     min-height: 50px;

            //     &.disabled {
            //         background-color: #E3E4E6 !important;
            //         opacity: 1;
            //     }

            // }

            // input,
            // select {
            //     &:focus {
            //         box-shadow: none !important;
            //         outline: none !important;
            //         border: none;
            //     }
            // }
        }
    }

    .form-control,
    .form-select {


        &:disabled {
            opacity: .7;

        }

        &.disabled {
            opacity: .7;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }

    .otp_form_style {
        >div {
            width: 100%;

            div {
                flex: 1;
                margin: 0 10px;
                @include gradientReverse();
                border-radius: 10px;
                padding: 1px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        input {
            border: none;
            border-radius: 8px;
            background-color: $lightGray;
            height: 36px;
            width: 100% !important;
            font-size: 30px;
        }
    }
}

.form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

.textarea {
    &:focus {
        border-color: $primary;
    }
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #000;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
    background-color: #000;
    border-color: #000;
}

.search_box {
    position: relative;

    .form-control {

        padding-left: 50px;
    }

    span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
    }
}

.radio_buttons_v {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {

        @include gradientReverse();
        border-radius: 12px;
        margin-bottom: .4rem;
        padding: 1px;

        .inner {
            display: flex;
            align-items: center;
            height: 47px;
            cursor: pointer;
            background-color: $lightGray;
            padding: 0px 20px;
            border-radius: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            .inner {
                @include gradientDark();
            }

            .r_check {
                color: #FFF;

                span {
                    border-color: #FFF;

                    i {
                        display: block;
                        color: #FFF;
                    }
                }
            }

            h5 {
                color: #FFF;
            }
        }

        .r_check {

            span {
                width: 24px;
                height: 24px;
                border: 1px solid $black;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 1rem;
                    line-height: unset;
                    display: none;
                }
            }
        }

        h5 {
            margin: 0 0 0 20px;
            font-size: 15px;
        }
    }
}

@media screen and (max-width: 767px) {

    .iconinput {


        &.top32 {
            top: 37px;
        }

        svg {
            width: 12px;

            path {
                fill: gray;
            }
        }

        &.Usernameindicater {
            top: 35px;
        }

        &.emailcross {
            top: 35px;
        }

        &.emailtick {
            top: 37px;
        }
    }

    .form-control {
        font-size: 16px !important;
    }

    .form_default {
        .otp_form_style {
            input {
                font-size: 16px;
            }

            >div {
                div {
                    margin: 0 2px;
                }
            }
        }
    }
}