
@mixin theme($light: true) {
  @if $light {
    background-color: $light-background;
    color: $light-text;
  } @else {
    background-color: $dark-background;
    color: $dark-text;
  }
}


@mixin themebg($light: true) {
  @if $light {
    background-color: #F5F5F5;
    color: $light-text;
  } @else {
    background-color: #253250;
    color: $dark-text;
  }
}