.wallet-card {
    min-height: 208px;
    background-image: url('../images/partner/thumnail.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    box-shadow: 0px 0px 1px 0px #0000001a, 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 24px;
    font-family: $poppin;

    button {
        white-space: nowrap;
    }

}

.mag-wallet-actions {
    .red {
        padding: 0 32px;
    }

    h4 {
        color: $primary;
        font-size: 28px;
        font-weight: 400;
        margin: 0;
    }

    .wallet-info {
        max-width: 80%;

        li {
            position: relative;
            padding-right: 40px;

            svg {
                width: 20px;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }

    .wallet-buttons {
        margin-left: 13px;
    }
}

.wallet-buttons {
    margin-right: 80px;
    gap: 20px;
    display: flex;
    align-items: center;


}

.wallet-head-icon {
    display: flex;
    gap: 10px;

    .icon {
        width: 56px;
        height: 56px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 64, 128, 0.05);
        border-radius: 10px;

        &.red {
            background-color: rgba(255, 0, 43, 0.1);
        }

        &.orange {
            background-color: rgba(255, 96, 64, 0.1);
        }

        &.yellow {
            background-color: rgba(255, 143, 64, 0.1);
        }

        svg {
            width: 32px;
        }
    }
}

.wallet-head {
    p {
        margin: 0;
        color: #525866;
        font-size: 16px;
        font-weight: 500;

    }

    h4 {
        margin: 0;
        font-weight: 400;
        font-size: 26px;
    }
}

.wallet-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    max-width: 400px;
    padding-top: 13px;
    margin-top: 13px;

    li {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;

        &:last-child {
            border-right: none;
        }

        >a {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px;
        }

        label {
            color: #525866;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
        }

        span {
            font-size: 18px;
            white-space: nowrap;
        }
    }
}

.wallet-wrap {
    .wallet-head {
        text-align: center;
    }

    .wallet-body {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 20px 0 10px 0;
        margin-bottom: 10px;

        .code {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            border-top: 1px solid rgba(0, 0, 0, 0.15);
        }

        .balance {
            display: flex;
            color: #7B8599;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            gap: 10px;

            span {
                font-weight: 700;
            }
        }
    }

    .wallet-footer {
        display: flex;
        gap: 20px;
        padding-top: 10px;
    }

}

[data-bs-theme="dark"] {
    .wallet-card {
        background-image: url('../images/partner/thumnail_dark.svg');
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: #000000;
        box-shadow: none;
    }

    .wallet-info {
        border-top: 1px solid rgba(255, 255, 255, 0.15);

        li {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
        }
    }
}

.mag-wallet {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 767px) {

    .mag-wallet {
        display: block;
    }
    .wallet-card {
        background-image: none;
    }

    .mag-wallet-actions {
        .wallet-info {
            max-width: 100%;
        }
    }

    .wallet-buttons {
        display: block;
        margin-right: 0;
        a {
            margin-top: 10px;
            display: block;
        }
    }

    .wallet-info {
        flex-wrap: wrap;
        li {
            border-right: none;
        }
    }
}