// /* MENU STYLES */
// .menu-wrap {



//   }

//   .menu-wrap .toggler {
//     position: absolute;
//     top: 0;
//     right: 0;
//     z-index: 2;
//     cursor: pointer;
//     width: 50px;
//     height: 50px;
//     opacity: 0;
//   }

//   .menu-wrap .hamburger {
//     margin-left: 20px;
//     width: 50px;
//     height: 50px;
//     padding: 0.7rem;
//     background: $primary;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   /* Hamburger Line */
//   .menu-wrap .hamburger > div {
//     position: relative;
//     flex: none;
//     width: 100%;
//     height: 2px;
//     background: #fff;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     transition: all 0.4s ease;
//   }

//   /* Hamburger Lines - Top & Bottom */
//   .menu-wrap .hamburger > div::before,
//   .menu-wrap .hamburger > div::after {
//     content: '';
//     position: absolute;
//     z-index: 1;
//     top: -10px;
//     width: 100%;
//     height: 2px;
//     background: inherit;
//   }

//   /* Moves Line Down */
//   .menu-wrap .hamburger > div::after {
//     top: 10px;
//   }

//   /* Toggler Animation */
//   .menu-wrap .toggler:checked + .hamburger > div {
//     transform: rotate(135deg);
//   }

//   /* Turns Lines Into X */
//   .menu-wrap .toggler:checked + .hamburger > div:before,
//   .menu-wrap .toggler:checked + .hamburger > div:after {
//     top: 0;
//     transform: rotate(90deg);
//   }

//   /* Rotate On Hover When Checked */
//   .menu-wrap .toggler:checked:hover + .hamburger > div {
//     transform: rotate(225deg);
//   }

//   /* Show Menu */
//   .menu-wrap .toggler:checked ~ .menu {
//     visibility: visible;
//   }

//   .menu-wrap .toggler:checked ~ .menu > div {
//     transform: scale(1);
//     transition-duration: var(--menu-speed);
//   }

//   .menu-wrap .toggler:checked ~ .menu > div > div {
//     opacity: 1;
//     transition:  opacity 0.4s ease 0.4s;
//   }



.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
  display: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #000000;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham3 {

  .top {
    stroke-dasharray: 40 130;
  }

  .middle {
    stroke-dasharray: 40 140;
  }

  .bottom {
    stroke-dasharray: 40 205;
  }

  &.active {
    .top {
      stroke-dasharray: 75 130;
      stroke-dashoffset: -63px;
    }

    .middle {
      stroke-dashoffset: -102px;
    }

    .bottom {
      stroke-dasharray: 110 205;
      stroke-dashoffset: -86px;
    }
  }
}


.ham4 {

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &.active {
    .top {
      stroke-dashoffset: -68px;
    }

    .bottom {
      stroke-dashoffset: -68px;
    }
  }
}