.modal-theme {

    &.size-md {
        .modal-dialog {
            max-width: 500px;
        }
    }


    .modal-header {
        background-color: $white;
        border: none;
        position: relative;

        &.header-dark {
            margin: -2px;

            .modal-title {
                color: #FFF;
            }

            .btn-close {
                filter: invert(100%);
            }
        }



        .modal-title {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 3px;
                height: 3px;
                width: 20px;
                background-color: $primary;
                border-radius: 10px;
            }
        }

        .h4 {
            font-size: 1rem;
        }
    }

    &.modal-small {
        .modal-dialog {
            max-width: 550px;
        }
    }


    &.modal-lg {
        .modal-dialog {
            max-width: 960px;

        }
    }

    &.modal-thumbnail {
        .modal-dialog {
            iframe {
                width: 100%;
                height: 397px;
                border-radius: 25px;
            }
        }
    }


    .modal-dialog {
        max-width: 800px;

    }

    .modal-body {
        img {
            border-radius: 12px;
        }
    }

    .modal-footer {
        padding: 1.2rem 2rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
}

.bg-img-modal {
    .modal-header {
        background-image: url('../images/realsto-popup-header.png');
        min-height: 115px;
        position: relative;
        filter: grayscale(1);

        .button-close {
            position: absolute;
            right: 1%;
            top: 3%;
        }

        .title {
            position: absolute;
            bottom: 0%;
            color: white;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

    .modal-body {
        background-image: url('../images/realsto-popup-bg.png');
    }
}

@media screen and (max-width:767px) {
    .bg-img-modal {
        .modal-header {

            .title {
                bottom: 2.5%;
            }
        }
    }

    .modal-theme {
        .modal-body {
            padding: 1.3rem;
        }

        &.modal-thumbnail {
            .modal-dialog {
                iframe {
                    height: 245px !important;
                }
            }
        }
    }
}