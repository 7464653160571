// print start
.page {
    width: 21cm;
    min-height: 28cm;
    padding: .5cm 1cm;
    margin: auto;
    // border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

// .subpage {

// height: 256mm;

// }

@page {
    size: A4;
    margin: 0;
}

.agreement-print {
    background: #FFFDEA;
    text-align: justify;
    box-shadow: none;

    >img {
        width: 100%;
        margin-bottom: 20pt;
    }

    font-size: 13px;

    .stamp {}

    .agreement-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 10pt 0;
        gap: 10pt;

        .left,
        .right {
            width: 48%;
            position: relative;
        }

        .lable {
            font-weight: 700;
        }

        .line {
            border-bottom: 1px solid black;

            p {
                margin: 10px 0px 2px 0;
                font-weight: 800;
            }

        }



        .sign {
            border-bottom: 1px solid black;
        }

        .stamp {
            position: absolute;
            top: -155px;
            right: 25px;
            width: 150px;
            rotate: 34deg;

        }
    }
}

.invoice-head {
    @include gradientDark();
    color: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    border-radius: 20px;
}

.invoice-data {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-weight: 500;

        &:last-child {
            border-bottom: none;
        }
    }
}

.invoice-address {
    h4 {
        font-size: 14px;
        color: $primary;
        margin: 0;
    }

    p {
        font-size: 12px;
    }
}

.address-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    i {
        color: $primary;
        font-size: 14px;
    }

    span {
        font-size: 13px;
        margin-left: 6px;
    }
}

.invoice-title {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    position: relative;
    text-transform: capitalize;

    &::after {
        content: "";
        height: 3px;
        width: 50px;
        position: absolute;
        left: 0;
        bottom: -10px;
        background-color: $primary;
    }
}

.invoice-heading {
    display: flex;
    justify-content: flex-start;

    label {
        position: relative;
        font-weight: 800;
        text-transform: capitalize;
        margin-bottom: 6px;
        color: $primary;

        &::after {
            content: "";
            height: 3px;
            width: 20px;
            position: absolute;
            left: 0;
            bottom: -5px;
            background-color: $primary;
        }
    }

    span {
        font-weight: 400;
        color: #000;
    }
}

.invoice-sign {
    margin-top: 10px;
    border-top: .8px solid rgba(0, 0, 0, 0.6);
    width: 100%;

    label {
        font-weight: 600;
        margin-top: 10px;
    }

}

.inv-box {
    border-radius: 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.6);
    padding: 20px;
    min-height: 114px;

    &.border-dashed {
        border-style: dashed;
    }

    p {
        color: rgba(0, 0, 0, 0.50);
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }

    h3 {
        color: #000;
        font-size: 18px;
        margin: 10px 0 0 0;
        font-weight: bold;
    }

    h4 {
        font-size: 14px;

        span {
            color: rgba(0, 0, 0, 0.50);
        }
    }
}

.inv-date {
    font-size: 13px;

    span {
        color: rgba(0, 0, 0, 0.50);
    }
}

.inv-bordered-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.column {
        flex-direction: column;
        align-items: flex-start;

        li {
            border-right: none;
            border-bottom: 1px solid rgba(0, 0, 0, .4);
            width: 100%;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    li {
        flex: 1 auto;
        border-right: 1px solid rgba(0, 0, 0, .4);
        padding: 0 20px;

        &:last-child {
            //padding-right: 0;
            border-right: 0;
        }

        span {
            color: rgba(0, 0, 0, 0.50);
            font-size: 13px;
            white-space: nowrap;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            color: #000;
        }
    }
}


#inv-footer {
    position: relative;
    border-top: 1px solid $smgray;
    margin: 20px 0;
    padding-top: 20px;

    // &::after {
    //     content: '';
    //     position: absolute;
    //     height: 3px;
    //     width: 112px;
    //     background-color: $primary;
    //     top: 0;
    //     right: 0;
    // }

}

.qr-wrap {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;

    img {
        width: 100%;
    }
}


.invoice-list-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
        margin-left: 30px;
        border-left: 2px solid $primary;
        padding-left: 8px;

        p {
            margin: 0;
            font-size: 13px;
            color: rgba(0, 0, 0, 0.50);
        }

        h4 {
            margin: 0;
            font-size: 15px;
            font-weight: 500;
        }
    }
}

.wallet-box {
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 144px;
    border-radius: 10px 0px 0px 10px;

    p {
        margin: 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.50);
    }

    h4 {
        margin: 5px 0 0 0;
        font-size: 17px;
        font-weight: 500;
    }
}

.wallet-amount-box {
    border-radius: 0px 10px 10px 0px;
    border: 0.5px dashed rgba(0, 0, 0, 0.50);
    height: 144px;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        width: 100%;

        li {
            display: flex;

            .w-label {
                color: rgba(0, 0, 0, 0.50);
                font-size: 14px;
                width: 45%;
                text-align: right;
            }

            .w-divider {
                width: 10%;
                text-align: center;
                color: rgba(0, 0, 0, 0.50);
                font-weight: bold;
            }

            .w-amount {
                font-weight: 500;
            }
        }
    }
}

.footer-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    p {
        font-size: 12px;
        color: #6d6d6d;
        margin: 0;
    }

    span {
        font-size: 20px;
        color: #6d6d6d;
    }

    h5 {
        font-size: 13px;
        margin: 0;
    }
}

.cash-collection-print {
    .collection-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        img {
            width: 250px;
        }

        .collection-text {
            background-color: $primary;
            height: 45px;
            padding: 10px 30px 10px 50px;
            position: relative;

            span {
                color: $white;
                font-size: 17px;
                font-family: $Playfair;
            }

            &::after {
                content: '';
                width: 45px;
                height: 45px;
                rotate: 45deg;
                position: absolute;
                background: $white;
                top: 0;
                left: 0;
                transform: translate(-50%, 50%);
            }
        }
    }

    .collection-footer {}
}

@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }

    .invoice-title {
        -webkit-print-color-adjust: exact !important;
    }

}

@media screen and (max-width:767px) {
    .page {
        margin-top: 30% !important;
    }
}

// print end
