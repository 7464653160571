.accordian-gradient {
    @include gradientReverse();
    padding: 3px;
    border-radius: 20px;

    .accordion-item {
        border-radius: 20px;
        background-color: $lightGray;

        .accordion-header {
            @include gradientReverse();
            border-radius: 20px;
            padding: 2px;
            margin: -4px;

            .accordian-right-action {
                position: absolute;
                right: 30px;
                top: 10px;
            }

            &.stars {
                .accordion-button {
                    font-size: 30px;
                    padding: 18px 0 5px 21px;
                }
            }

            .accordion-button {
                background-color: $lightGray;
                border-radius: 20px;
                color: #8D8D8D;

                &:focus {
                    box-shadow: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}

.disable-accordion {
    button {
        pointer-events: none;
    }
}

.no-dropdown-arrow {
    .accordion-button::after {
        display: none;
    }
}

#menu {
    padding: 0;
}

// custom accordion 

/* Initially hide all submenus */
.submenu {
    display: none;
}

/* Style for active submenu */
.submenu.open {
    display: block;
}

.drowpdown-accordion {
    margin-bottom: 10px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    cursor: pointer;
    background-color: #111925 !important;
    font-size: 13px;
    color: rgba(255, 255, 255, .7);
    border-radius: 5px;
    min-width: 100%;

    .header {
        background-color: $primary !important;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.7);

        i {
            margin-right: 10px;
        }

        p {
            margin: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 13px;
            right: 16px;
            width: 20px;
            height: 20px;
            transform: rotate(-180deg);
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
            transition: transform 0.3s ease;
        }

        &.close {
            background-color: $primary;
            color: rgba(255, 255, 255, 0.7);
            position: relative;

            &::after {
                transform: rotate(0deg);
            }
        }

    }

    .submenu {
        padding: 8px 1px 8px 17px;
        background-color: #111925;

       
        li {
            list-style: none;
            margin: 0;
            background: #111925;
            color: #ffffffb3;
            min-height: 52px;
            border-radius: 5px;

            .active {
                background-color: $primary;
            }


            a {
                position: relative;
                display: flex;
                align-items: center;
                padding: 6px 16px;
                font-size: 13px;
                border-radius: 5px;
                color: rgba(255, 255, 255, 0.7);
                margin-top: 5px;
                min-height: 52px;

                span {
                    margin-left: 10px;
                }

                &:hover,
                &:focus,
                &:active,
                &:target {
                    background-color: $primary;

                }
            }
        }
    }
}