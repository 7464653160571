.card {
    background-color: #FFF;
    border: none;
    padding: 0;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;

    display: block;

    &.card_lg {
        padding: 50px 66px;
    }

    .card_label {
        background: rgb(243, 243, 243);
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 12px;
        color: rgb(142, 142, 142);
        text-transform: uppercase;
        font-size: 12px;
        display: inline-block;

    }

    .card_title {
        font-size: 16px;
        color: $black;
    }

    .card_text {
        color: #8E8E8E;
    }
}


.gradient-box {



    position: relative;



    $border: 2px;

    background: $lightGray;
    background-clip: padding-box;
    /* !importanté */
    border: solid $border transparent;
    /* !importanté */
    border-radius: 1em;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -$border;
        /* !importanté */
        border-radius: inherit;
        /* !importanté */
        @include gradientReverse();
    }
}


.card_above_label {
    font-size: 14px;
    color: rgb(142, 142, 142);
}

.card_action_right {
    position: absolute;
    right: 3rem;
    top: 2rem;

    .card_action_menu {
        a {
            margin-left: 1.6rem;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.card-table {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #FFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;

    .card-inner {
        background-color: $white;
        overflow: hidden;

        .table {

            margin: 0;

            thead {
                background: #F8FAFF;
                border: transparent !important;


                tr {
                    th {
                        padding: 12px 20px;
                        text-wrap: nowrap;
                        border: transparent !important;
                        color: #525866;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }



            }

            tbody {
                tr {

                    td {
                        border-color: #f0f0f0;
                        text-wrap: nowrap;
                        padding: 12px 20px;

                        .total {
                            color: #525866;
                            font-size: 16px;
                            font-weight: 700;
                        }

                        &.else {
                            text-align: center;

                            svg {
                                width: 40px;
                                stroke: #c5c5c5;
                                margin-bottom: 10px;
                            }

                            h4 {
                                font-size: 14px;
                                margin: 0;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: none !important;
                    }
                }
            }
        }

    }
}

.card-gradient {

    padding: 2px;
    border-radius: 20px;

    &.radius-12 {
        border-radius: 12px;

        .card-inner {
            border-radius: 10px;
        }
    }

    .card-inner {
        background-color: #FAFAFA;
        border-radius: 18px;
        padding: 40px;

        &.p2 {
            padding: 2px;
        }

        .card-inner-header {
            min-height: 70px;
            padding: 20px;
            position: relative;
            font-weight: 500;
            color: #000;
            font-size: 18px;


        }
    }
}

@media screen and (max-width: 767px) {

    .card {
        .card_title {
            font-size: 14px;
        }
    }

    .card_action_right {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 2rem;

        .card_action_menu {
            a {
                margin-left: 1.3rem;

                svg {
                    width: 25px;
                    height: 25px;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .card {
        &.card_lg {
            padding: 20px 30px;
        }

    }

    .card-gradient {
        .card-inner {
            padding: 15px !important;

            &.p-0 {
                padding: 0 !important;
            }
        }
    }
}