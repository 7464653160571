.kyc_left_menu {
    
    padding: 2px;
    @include gradientReverse();
    width: 100%;
    border-radius: 20px;
    .inner {
        padding-top: 50px;
        background-color: $lightGray;
        height: 80vh;
        border-radius: 18px;
    }
    .logo {
        text-align: center;
    }

    ul {
        margin: 60px 0 0 0;
        padding: 0;
        list-style-type: none;

        li {
            text-align: center;
            position: relative;

            &:first-child {
                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    @include gradientReverse();
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                @include gradientReverse();
                position: absolute;
                bottom: 0;
                left: 0;
            }
           
            a {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.6);
                padding: 12px 0;
                display: block;
                &.active {
                    font-weight: 600;
                    color: $black;
                }
            }
        }
    }

}

.kyc_wrap {
    background-color: #FFF;
    max-width: 900px;
    margin: auto;
    .kyc_row {
        display: block;

        .kycleft {
            flex-basis: 18%;
           

            .kyc_terms {
                margin-top: 50px;
                text-align: center;
            }
        }

        .kycright {
            flex-basis: 82%;

            .kyc_content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                
              
            }
        }

    }

    .kyc_terms {
        display: flex;
        flex-direction: column;
        margin-top: auto;

        a {
            display: block;
            margin-bottom: 6px;
            color: $black;
            font-size: 13px;
        }
    }
}

.agreement_wrap {
    max-width: 900px;

    .agreement_head {
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 2rem 0;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 40px;
    }

    .agreement_footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 2rem 0;
        font-size: 20px;
        font-weight: bold;
        margin-top: 40px;

        .form-check {
            margin-bottom: 1rem;

            label {
                font-size: 16px;
                font-weight: 400;
                color: rgb(90, 90, 90);
            }
        }
    }

    p {
        text-align: justify;
    }
}

.kyc_1 {
    h2 {
        font-weight: 600;
        font-size: 34px;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        color: rgb(142, 142, 142);
    }
}

.kyc_title {
    h3 {
        font-weight: 600;
        font-size: 20px;
        color: $black;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        color: rgb(142, 142, 142);
        margin-bottom: 40px;
        max-width: 600px;
    }
}

.offcanvas_country {
    width: 100%;
    height: 600px;

    .offcanvas-title {
        width: 100%;
        text-align: center;
    }
}

.card_list_of_countries {
    position: absolute;
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 74px;
    z-index: 1;
    @include ScrollContent();
}

.countries_list_offcanvas {
    margin: 0;
    padding: 8px;
    list-style-type: none;
    background-color: $lightGray;
   
    li {
        font-size: 1rem;
        margin-bottom: .6rem;
        cursor: pointer;
        img {
            width: 22px;
            margin-right: 8px;
        }
        
        &:hover {
            color: $primary;
        }
        
    }
}

.cam_wrap {
    video {
        width: 100% !important;
    }
}

.upload-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(45, 45, 45);
    padding: 40px;
    flex-direction: column;

    label {
        text-align: center;
    }

    h4 {
        font-weight: 500;
        font-size: 16px;
    }
    p {
        font-size: 12px;
        margin-bottom: 20px;
        color: rgb(181, 168, 168);
    }

    input {
        position: absolute;
        opacity: 0;
        left: 0;
    }
}

.webcam_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 400px;
    overflow: hidden;
    margin: auto;
    border-radius: 50%;
    border: 3px dashed gray;
   
}

.button-capture {
    text-align: center;
}

@media screen and (max-width:767px) {
    .kyc_box{
        margin-top: 60px;
    }
    .kyc_title {
        h3 {
            
            font-size: 16px;
    
        }
    
        p {
            font-size: 14px;
            
        }
    }
    
}