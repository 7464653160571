.Toastify__toast {
    position: relative;
    padding: 8px;
    border-radius: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: space-between;
    font-family: $poppin !important;

    .Toastify__toast-body {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 15px;

        >div {
            margin: 45px 0 10px 0;
            color: #000;
        }

        .Toastify__toast-icon {
            display: none;
        }

    }

    .Toastify__close-button {
        display: none;
    }

    .Toastify__progress-bar {
        bottom: 10px !important;
        left: 15px !important;
        width: 90% !important;
        height: 7px !important;
        opacity: 1 !important;
        transform-origin: left !important;
        box-shadow: none !important;
        border-radius: 10px !important;

        &.Toastify__progress-bar--success {
            background: #408040 !important;
        }

        &.Toastify__progress-bar--error {
            background: #FF002B !important;
        }
    }


}

.Toastify__toast--success {

    &::before {
        content: url("../images/partner/tosat/successToast.svg");
        position: absolute;
        z-index: 100000;
    }

    &::after {
        content: url('../images/partner/tosat/closeToast.svg');
        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.Toastify__toast--error {

    &::after {
        content: url('../images/partner/tosat/closeToast.svg');
        position: absolute;
        right: 15px;
        top: 15px;
    }

    &::before {
        content: url("../images/partner/tosat/errorToast.svg");
        position: absolute;
        z-index: 100000;
    }
}

[data-bs-theme="dark"]{
    .Toastify__toast {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #000000;
        box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16prgba(255, 255, 255, 0.02) ;
    
        .Toastify__toast-body {
            >div {
                color: #ffffff;
            }
        }
    
    }
    
    .Toastify__toast--success {
    
        &::before {
            content: url("../images/partner/tosat/successToastDark.svg");
        }
    }
    
    .Toastify__toast--error {
    
        &::before {
            content: url("../images/partner/tosat/errorToastDark.svg");
        }
    }
}