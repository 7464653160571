.page-head {
    background-image: url("../images/page-header.jpg");
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 12rem;
    h1 {
        text-align: center;
        color: #FFF;
        font-family: 'Krub';
    }
}


.page-content {
    background-color: #1b1b1a;
    color: #FFF;
    padding: 3rem 0;
}