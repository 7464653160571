.theme-icons {
    position: fixed;
    bottom: 20px;
    left: 20px;
    cursor: pointer;
    transition: background-color 0.2s;

    .iocn {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        @include flexcenter;
        border-radius: 32px;
        background: #FFF;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);

        .sun {
            stroke: $primary;
        }

        svg {
            width: 24px;
        }

    }
}

.support-pannel {

    .else {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-top: 10%;

        h4 {
            color: #515151;
            margin: 0;
            font-size: 15px;
        }

        svg {
            width: 100px;
            color: #e9e9e9;
        }
    }
}

.support-icons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    @include flexcenter;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 56px;

    .main-iocn {
        a {
            @include flexcenter;
            width: 56px;
            height: 56px;
            display: none;

        }

        &.active {
            a {
                display: flex;
            }
        }
    }

    .open-icon {
        width: 40px;
        height: 40px;
    }

    .main-iocn,
    .open-icon {
        @include flexcenter;
        border-radius: 32px;
        background: #FFF;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);

        svg {
            width: 24px;
        }
    }
}

[data-bs-theme="dark"] {

    .support-pannel-offcanvas {
        background-color: #17181a;

        .offcanvas-title {
            color: $white;
        }

        .btn-close {
            width: 25px;
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/contain no-repeat;
            margin: 0;
            padding: 0;
        }

        .nav-support {
            background-color: #000000 !important;
            border-bottom: none;

            svg {
                path {
                    stroke: #525866;
                }
            }
        }

        .new-ticket-wrap {
            .icon-input {

                input,
                .form-select,
                textarea {
                    background: #000;
                    color: $white;

                    &::placeholder {
                        color: $white;
                    }
                }
            }

            .attachment-link {
                span {
                    color: rgb(255 255 255 / 60%);
                }

                svg {
                    stroke: #fff;
                }
            }
        }

        .inbox-item {
            background: #000;
            border: 1px solid rgba(255, 255, 255, 0.15);

            .inbox-menu {
                svg {
                    stroke: #525866;
                }

                p {
                    color: #525866;
                }
            }

            >span {
                background: #525866;
                color: #fff;
            }

            &:hover {
                border: 1px solid $primary;

                .inbox-menu {
                    svg {
                        stroke: $primary;
                    }

                    p {
                        color: $white;
                    }
                }

                >span {
                    background: $primary;
                    color: $white;
                }
            }
        }

        .tickte-detail-head {
            svg {
                stroke: $white;
            }

            h3 {
                color: $white;
            }
        }

        .list-support-tickets {
            li {

                h2,
                >div {
                    color: $white;
                }

                .ticket-id {
                    color: $primary;
                }

                .button {
                    background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                    color: #FFF;
                    border: none;
                }
            }
        }

        .bs-comment-post {
            .post-info-actions {
                .attachment-link {
                    span {
                        color: rgb(255 255 255 / 60%);
                    }

                    svg {
                        stroke: $primary;
                    }
                }
            }

            .user-reply {
                .comment-message {
                    .inner {
                        background: rgb(255 16 9 / 47%) !important;

                        p {
                            color: $white;
                        }

                        .comment-actions {
                            .date {
                                color: #a1a1a1;
                            }
                        }
                    }
                }
            }

            .admin-reply {
                .comment-message {
                    .inner {
                        background: rgba(0, 0, 0, 0.47) !important;

                        p {
                            color: $white;
                        }

                        .comment-actions {
                            .date {
                                color: #a1a1a1;
                            }
                        }
                    }
                }
            }
        }

        .reply {
            background: #000;
            svg{
                stroke: $primary;
            }
            .form-control{
                background: #17181a;
                color: $white;
            }
        }

        .else {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-top: 10%;

            h4 {
                color: #515151;
                margin: 0;
                font-size: 15px;
            }

            svg {
                width: 100px;
                color: #e9e9e9;
            }
        }
    }

    .upload-img-model {
        .modal-content {
            background-color: #17181a;
        }

        .modal-title {
            color: $white;
        }

        .btn-close {
            width: 25px;
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/contain no-repeat;
            padding: 0;
            margin: 0;
        }

        .model-pink {
            background: #000000;
        }

        .thumnail {

            svg,
            h6 {
                color: $white;
            }
        }
    }

    .theme-icons {
        .iocn {
            background: #000000;
            box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);

            svg {
                fill: $primary;
                stroke: $primary;
            }
        }
    }

    .support-icons {

        .main-iocn,
        .open-icon {

            background: #000000;
            box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.05), 0px 4px 16px 0px rgba(255, 255, 255, 0.02);

            svg {
                path {
                    fill: $primary;
                }
            }
        }
    }
}