body {
    background-color: #FFF;

}

#main {
    padding: 8rem 0 0 0;
    // display: flex;
}



.user_balance_box {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 2rem;

    .balance {
        display: flex;
        align-items: center;

        .icon {
            flex-shrink: 0;

            span {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #F2F8ED;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 2rem;
                    color: $primary;
                }
            }
        }

        .info {
            padding-left: 0.5rem;
            line-height: 18px;

            h4 {
                margin: 0;
                font-size: 1.3rem;
            }

            p {
                margin: 0;
                font-size: .8rem;
            }
        }
    }

    .button-refresh {
        padding: 0;
        margin: 0 .6rem;
        background-color: transparent;
    }


}

.dashboard_cashback {

    h1 {
        font-weight: 600;
        font-size: 25px;

        span {
            font-size: 25px;
        }
    }

    p {
        color: #8E8E8E;
        max-width: 567px;
        font-size: 13px;
    }
}

.card_user_circle {
    position: relative;
    text-align: center;
    margin: auto;

    .img-wrap {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        padding: 2px;
        margin: auto;

        .inner {

            background-color: $lightGray;
            border-radius: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .img-placeholder {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;

                .placeholder {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    display: block;
                }
            }

            img {
                border-radius: 50%;
                margin: auto;
                width: 88%;
            }

        }
    }

    h4 {
        color: $primary;
        font-size: 20px;
        font-weight: 600;
    }

    .caption {
        margin-top: 20px;

        h3 {
            font-weight: 600;
            font-size: 24px;
            margin: 0;
            color: $primary;
            text-transform: uppercase;
        }

    }
}

.db_cb_item {
    margin: 2rem 0;

    .gradient {
        padding: 2px;
        border-radius: 12px;

        .inner {
            background-color: $lightGray;
            border-radius: 10px;
            padding: 8px 20px;
        }
    }



    p {
        margin: 0 0 5px 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        text-transform: uppercase;
        color: rgb(142, 142, 142);
    }

    h2 {
        margin: 0;
        font-weight: 700;
        font-size: 23px;
    }


}

.table-orders {
    thead {
        th {
            color: #9FA2A9;
        }
    }

    .cname {
        color: $black;
        font-weight: 600;
    }

    td {
        border: none;
        color: #6F656D;

        &.price {
            color: $primary;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 767px) {
    .dashboard_cashback {

        h1 {
            font-size: 16px;

            span {
                font-size: 16px;
            }
        }
    }

    .card_user_circle {
        position: relative;
        text-align: center;
        margin: auto;


        h4 {
            margin-top: 50px;
        }

    }


    .card_user_circle {
        img {
            margin: 74px auto auto 18px;
        }
    }

    .db_cb_item {
        padding: 3px 0;
        margin: 0;

        .gradient {
            .inner {
                padding: 8px 15px;

                h2 {
                    font-size: 17px;
                }
            }
        }

    }

    .mobile-btn-view {
        margin-top: 10px;
        margin-left: 5px;
    }

    .join_date_boxes {
        span {
            margin: 8px -9px;
            padding: 17px !important;
            width: 32px !important;
            height: 32px !important;
            font-size: 22px !important;
        }
    }

    .d-title {
        padding: 0;
        margin-bottom: 10px;
        font-size: 20px;
        text-align: center;
        font-weight: 600;
    }

    #main {
        padding: 3rem 0 0 0;
    }

    .user_balance_box {
        margin-left: 56px;
        margin-right: 0;

        .balance {
            .icon {
                display: none;
            }

            .info {
                padding-left: 0;
                line-height: 14px;
            }
        }

        .button-refresh {
            padding: 0;
            margin: 0;
        }

        .button-green {
            padding: 0.3rem 0.5rem;
            font-size: 0.7rem;
        }
    }
}