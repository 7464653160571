.package-section {


    .demo-bg {
        background-image: url('../images/partner/package-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        border: 1px solid #FF002A;
        position: relative;
        min-height: 565px;

        .heading {
            position: absolute;
            top: 4%;
            left: 4%;
            color: $white;
        }

        .demo {
            padding: 50px 70px;

            .carousel-indicators {
                position: absolute;
                right: 0;
                bottom: 8px;
                left: 0;
                z-index: 2;
                display: flex;
                justify-content: center;
                padding: 0;
                margin-right: 15%;
                margin-bottom: 0;
                margin-left: 15%;
                list-style: none;

            }



            .slick-prev,
            .slick-next {
                width: 40px !important;
                height: 40px !important;
                border: 1px solid rgba(255, 255, 255, 0.45) !important;
                padding: 10px !important;
                transform: translate(0, -50%);
                color: $white !important;
                border: 1px solid !important;
                padding: 5px;
                background: transparent !important;
                border-radius: 50% !important;
                stroke-width: 2px;
            }
        }
    }
}

.slider-dots {

    .slick-dots {

        li {

            cursor: pointer;

            button {
                font-size: 18px;
                line-height: 1;
                opacity: 100;
                width: 12px !important;
                height: 12px !important;
                background-color: $white !important;
                border-radius: 50%;
                padding: 0 !important;
                font-size: 0;

                &:before {
                    display: none;
                }
            }

            &.slick-active button {
                background: white !important;
                border-radius: 50%;
                position: relative !important;
                width: 16px !important;
                height: 16px !important;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 10px !important;
                    height: 10px !important;
                    content: '' !important;
                    display: block !important;
                    color: $primary !important;
                    border-radius: 50%;
                    background: $primary;
                }
            }
        }
    }
}

.package-detail-section {
    .slider-dots {
        .slick-dots {
            margin-bottom: 50px;
        }
    }
}

.ribbon {
    position: relative;
    display: inline-block;
    padding: 1px 25px 1px 15px;
    background: #F2DADE;
    color: #fff;
    border-radius: 4px 0px 0px 0px;
    position: relative;
    font-family: $poppin;

    &.primary {
        background-color: $primary;
    }

    .ribbon-corner {
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 16px 16px 0;
        border-color: transparent #525866 transparent transparent;

        .ribbon-text {
            font-size: 16px;
            text-transform: uppercase;
        }

    }

    &::after {
        content: "";
        position: absolute;
        top: 5px;
        right: -7px;
        transform: rotate(44deg);
        height: 15px;
        background: white;
        width: 15px;
    }
}

.responsive-package-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 30px;
    padding: 0;
    margin: 0;

    >li {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 24px;
        padding: 8px;
        width: 330px;
        border-radius: 16px;
        background: linear-gradient(0deg, #F5F5F7 0%, #F5F5F7 100%), #FFF;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);

        &.active {
            background: linear-gradient(180deg, $primary 0%, $primary 29.98%, #FFF 29.98%, #FFF 100%);
        }
    }
}

.package-card-view-button {
    >div {
        background: linear-gradient(0deg, #F5F5F7 0%, #F5F5F7 100%), #FFF;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        min-height: 400px;

        .package-card {
            align-items: center;
            position: relative;
            box-shadow: none;
        }
    }


}

.package-card-view {

    margin-bottom: 24px;
    padding: 8px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .package-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 30px;
        background: linear-gradient(0deg, #F5F5F7 0%, #F5F5F7 100%), #FFF;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
        width: 544px !important;
        border-radius: 16px;
        padding: 10px;

        .image {
            border-radius: 12px;
            min-width: 240px;
            height: auto;


            img {
                border-radius: 12px;
                width: 100%;
                height: auto;
            }

        }

        .content {
            .detail {
                span {
                    color: #000;
                }
            }

            .ribbon {
                .ribbon-text {
                    color: $white;
                }
            }
        }


    }
}

.package-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 10px;
    font-family: $poppin;

    .image {
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        height: 312px;
        object-fit: cover;

        &.placeholder {
            width: 100%;
            background-color: #edf4fa;
            height: 300px;
            @include flexcenter;
            border-radius: 12px;

            span {
                font-size: 200px;
                color: $white;
            }
        }

        img {
            border-radius: 12px;
            width: 100%;
            object-fit: cover;
        }

    }

    .content {
        display: flex;
        min-width: 232px;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        color: #000;

        .ribbon {
            margin: 10px 10px 0 -23px;
            background: var(--ribboncolor);
            color: var(--ribbontext);

            &.Starter {
                background: #B68279;
            }

            &.Standard {
                background: #AEA2B0;
            }

            &.Superior {
                background: #FBBF80;
            }

            &.package_1 {
                background: #f2e3b6;
            }

            &.package_2 {
                background: #F2DADE;
            }

            &.package_3 {
                background: #f2e8da;
            }

            &.package_4 {
                background: #dadae5;
            }

            &.Gold {
                background: #f2e3b6;
            }

            &.Diamond {
                background: #F2DADE;
            }

            &.Platinum {
                background: #f2e8da;
            }

            &.Titanium {
                background: #dadae5;
            }
        }

        .package-header {
            border-bottom: 1px solid $primary;
            position: relative;
            width: 100%;
            min-height: 59px;

            &::before {
                content: '';
                position: absolute;
                height: 5px;
                width: 20px;
                background-color: $primary;
                bottom: -6px;
                left: 0;
            }

            label {
                color: #525866;
                font-size: 16px;
                text-transform: uppercase;
            }

            h4 {
                text-align: left;
                font-size: 21px;
                font-style: italic;
                font-weight: 500;
                font-family: $Playfair;
            }
        }

        .detail {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            position: relative;

            svg {
                position: absolute;
                content: '';
                width: 15px;
                height: 15px;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);
                background: $white;

                &.Starter {
                    path {
                        fill: #B68279;
                    }
                }

                &.Standard {
                    path {
                        fill: #AEA2B0;
                    }
                }

                &.Superior {
                    path {
                        fill: #FBBF80;
                    }
                }

                &.package_1 {
                    path {
                        fill: #f2e3b6;
                    }

                }

                &.package_2 {
                    path {
                        fill: #F2DADE;
                    }
                }

                &.package_3 {
                    path {
                        fill: #f2e8da;
                    }
                }

                &.package_4 {
                    path {
                        fill: #dadae5;
                    }
                }

                &.Gold {
                    path {
                        fill: #f2e3b6;
                    }

                }

                &.Diamond {
                    path {
                        fill: #F2DADE;
                    }
                }

                &.Platinum {
                    path {
                        fill: #f2e8da;
                    }
                }

                &.Titanium {
                    path {
                        fill: #dadae5;
                    }
                }
            }

            p {
                color: #000;
                font-size: 20px;
                margin: 0;
            }

            h6 {
                color: #000;
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }

            span {
                color: #7A8599;
                font-size: 16px;
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;
            margin: 0;
            margin-top: 10px;
            list-style-type: disc;
            gap: 1px;
            width: 100%;

            li {
                display: flex;
                justify-content: space-between;
                text-align: center;
                position: relative;
                padding-right: 10px;

                &::before {
                    content: "";
                    position: absolute;
                    height: 6px;
                    width: 6px;
                    border-radius: 20px;
                    background-color: #000000;
                    top: 8px;
                    left: -16px;
                    transform: translate(-50%, 50%);
                }
            }

            span {
                font-weight: 600;
            }
        }

        .package-footer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-top: 10px;
            column-gap: 15px;
            margin-left: 10px;

            a {
                color: #525966;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
}

.active-package-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 494px;

    li {
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        padding: 10px;
        width: 100%;

        &:first-child {
            font-weight: 600;

            .sr {
                background-color: transparent;
                color: #000;
            }
        }

        .sr {
            width: 30px;
            height: 30px;
            @include flexcenter;
            background-color: $primary;
            border-radius: 50%;
            color: $white;
            flex-shrink: 0;
        }

        .package {
            width: 100%;
        }

        .values {
            width: 120px;
        }

        &.active {
            background: #F5F7FC;
        }

        .package {
            h1 {
                font-size: 16px;
                margin: 0;
            }

            label {
                font-size: 13px;
            }

            h6 {
                font-size: 13px;
                font-weight: 600;
                margin: 0;
            }
        }

        .values {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                margin: 0;
            }

            span {
                color: #FF002B;
                font-size: 12px;
            }

            &.paid {
                align-items: flex-end;
            }

            &.pending {
                align-items: center;

                span {

                    color: #7B8599;
                }
            }
        }
    }
}

.properte-card {
    position: relative;
    font-family: $poppin;

    .ribbon {
        position: absolute;
        margin: 22px 10px 0px -15px;
        background: $primary;
        background: var(--ribboncolor);
        color: var(--ribbontext);
    }

    .content {
        display: flex;
        min-width: 232px;
        padding: 15px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        color: #000;



        .package-header {

            position: relative;
            width: 100%;
            margin-top: 45px;

            label {
                color: #525866;
                font-size: 16px;
                text-transform: uppercase;
            }

            h4 {
                text-align: left;
                font-size: 21px;
                font-style: italic;
                font-weight: 500;
                font-family: $Playfair;
                margin: 0;
            }
        }


        >ul {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;
            margin: 0;
            margin-top: 10px;
            list-style-type: none;
            width: 100%;
            padding: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.15);
            padding-top: 10px;
            row-gap: 10px;

            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                label {
                    color: #525866;
                    font-size: 14px;
                    line-height: 16px;
                }

                span {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .princing {
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;

            }
        }








        .package-footer {
            width: 100%;
        }
    }
}

.color-chose {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0;
    list-style-type: none;


    li {
        display: inline-block;

        input[type="radio"] {
            display: none;
        }

        label {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            cursor: pointer;
            transition: box-shadow 0.3s;

            &:hover {
                box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.9);
            }
        }

        input[type="radio"]:checked+label {
            box-shadow: inset 0 0 4px 0px rgba(0, 0, 0, 0.9);
        }
    }
}



.opportunity-icon-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    gap: 10px;
    width: 100%;
    font-family: $poppin;

    .info {
        display: flex;
        gap: 10px;
        width: 100%;
    }

    .user-card {
        width: 100%;

        .front,
        .back {
            border-radius: 10px;
        }

        img {
            border-radius: 10px;
        }
    }

    h1 {
        font-family: $Playfair;
        color: $primary;
        font-size: 24px;
        font-style: italic;
        font-weight: 500;
        margin: 0;
    }

    >span {
        font-size: 14px;
        line-height: 16px;
    }

    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding-bottom: 8px;
        gap: 10px;

        &:last-child {
            border-bottom: none;
        }

        &.close {
            svg {
                color: rgba(123, 133, 153, 1);
            }
        }

        svg {
            width: 22px;
            height: 22px;
            @include flexcenter;
            border-radius: 50%;
            font-size: 1rem;
            color: #000;
        }



        p {

            font-size: 14px;
            margin: 0;
            text-wrap: nowrap;
        }
    }
}

.product-feature {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
    font-family: $poppin;
    // background: linear-gradient(90deg, #F8FAFF 0%, #F8FAFF 15%, #FFF 15%, rgba(255, 255, 255, 0.00) 50%), #FFF;
    background: #FFF;
    padding: 40px 20px 10px 20px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        list-style-type: none;

        li {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 20px;

            a {
                color: #000;
            }

            &:hover {
                a {
                    color: $primary;
                }
            }

            &:last-child {
                border-bottom: none !important;
                padding-bottom: 0;
            }

            .product {
                width: 30%;
                font-size: 14px;
                font-weight: 700;
            }

            .product-info {
                width: 70%;
            }
        }
    }
}

.purchase-now-card {
    border-radius: 16px;

    .modal-content {
        width: 640px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);

    }

    .modal-header {
        height: 64px;
        flex-shrink: 0;
        background: #F8FAFF;
        justify-content: center;
        border-bottom: 0;
        border-radius: 16px 16px 0 0;
    }

    .modal-body {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        justify-content: center;
    }


    .verify {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;

        .icon-input {
            width: 100%;
        }

        .button {
            border-radius: 12px;
        }
    }

    .form-check {
        cursor: pointer;

        .form-check-label {
            color: #525866;
            font-size: 14px;
            letter-spacing: 0.4px;
        }

        .link {
            margin-left: 10px;
            color: #525866;
            font-size: 12px;
            letter-spacing: 0.4px;
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }

    .help {
        @include flexcenter;
        gap: 10px;
        width: 100%;

        label {
            color: #525866;
            font-size: 16px;
        }
    }


    .condition {
        width: 100%;

        label {
            font-size: .9rem;
        }

        margin:0;
        display: flex;
        flex-direction: column;
        gap: 14px;



        .select {
            width: 100%;
        }
    }

    .balance {
        @include flexcenter;
        flex-direction: column;
        width: 100%;

        label {
            color: #525866;
            font-size: 16px;
            font-weight: 500;
        }

        span {
            color: $primary;
            font-size: 28px;
            font-weight: 600;

        }

    }

}


.calc-result {
    background-image: url("../images/calcbg.svg");
    background-size: cover;
    border-radius: 16px;
    box-shadow: 0px 16px 24px -16px #CC7A96;
    padding: 32px;
}

.calc-title {
    font-size: 16px;
    font-weight: 600;
}

.calc-progress {
    height: 10px;
    background: #F2E6E8;
    border-radius: 5px;
    margin-top: 20px;

    >div {
        height: 10px;
        border-radius: 5px;
        background-color: $primary;
    }
}

.calc-item {
    margin-top: 20px;
    padding-right: 40px;

    .range-list {
        margin: 10px 0;
        padding: 0;
        height: 10px;

        background: #F2E6E8;
        border-radius: 5px;
        list-style-type: none;
        display: flex;
        justify-content: space-around;

        li {
            margin-top: -8px;

            button {
                width: 28px;
                height: 28px;
                cursor: pointer;
                border: none;
                border-radius: 50%;
                background-color: #efefef;


                &.active {
                    border: 4px solid #FFF;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
                    background: $primary;
                }
            }
        }
    }

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 0;
    }

    input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        margin: 10px 0;
    }

    /* Apply custom track styles */
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: #F2E6E8;
        border-radius: 5px;
    }

    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: #F2E6E8;
        border-radius: 5px;
    }

    /* Apply custom thumb styles */
    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 28px;
        height: 28px;
        cursor: pointer;
        background: $primary;
        border-radius: 50%;
        border: 4px solid #FFF;
        margin-top: -10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }

    input[type="range"]::-moz-range-thumb {
        width: 28px;
        height: 28px;
        cursor: pointer;
        background: $primary;
        border-radius: 50%;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    }

    /* Adjust thumb position for Firefox */
    input[type="range"]::-moz-range-progress {
        background-color: $primary;
    }
}

.calc-detail-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }
}

[data-bs-theme="dark"] {
    .responsive-package-list {
        >li {
            background: #000000;
            box-shadow: 0px 3px 12px 0px rgba(255, 255, 255, 0.2);
        }
    }

    .package-card {
        .content {
            background: #17181a;

            .ribbon {
                &::after {
                    background: #17181a;
                }
            }

            .package-header {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                &::before {
                    background-color: $primary;
                }

                label {
                    color: $primary;
                }

                h4 {
                    color: #ffffff;
                }
            }

            .detail {

                border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                svg {
                    background: #000;

                    path {
                        fill: $primary;
                    }
                }

                p {
                    color: $primary;
                }

                h6 {
                    color: #ffffff;
                }

                span {
                    color: #7A8599;
                    font-size: 16px;
                }
            }

            .list {
                color: #ffffff;

                &::before {
                    background-color: $white;
                }
            }
        }
    }

    .package-card-view {


        .package-card {
            background: #000;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
        }
    }

    .active-package-list {
        li {
            &.active {
                background: #17181a;
            }
        }
    }

    .properte-card {
        background: $black;

        label {
            color: $gray;
        }

        .price {
            color: $primary
        }

        .listing {
            background: #111111;
        }

    }

    .purchase-now-card {
        .modal-header {
            background: #1f1f1f;

            .modal-title {
                color: $white;
            }
        }

        .modal-body {
            color: $white;
        }

        .modal-content {
            background-color: #000000;
        }

        .verify {
            .form-control {
                height: 44px;
                background: #1f1f1f;
                border-color: $black;
                color: $white;
            }
        }

        .conditions {
            .form-control {
                background: #1f1f1f;
                border-color: $black;
                color: $white;
            }

            label {
                color: white;
            }
        }

        .purchase-list {
            li {

                &::before {
                    color: $primary;
                    border: 1px solid $primary;
                }

                label {
                    color: $white;
                }

                span {
                    background-color: $primary;
                }
            }

        }

        .conditions {

            li {
                a {
                    color: $primary;
                }
            }
        }
    }

    .properte-card {
        .ribbon {
            &::after {
                background: #000;
            }
        }

        .package-header {
            color: $white;
        }

        .content {
            >ul {
                label {
                    color: $white;
                }

                span {
                    color: #525866;
                }
            }

            .princing {
                span {
                    color: $primary;
                }
            }
        }
    }

    .product-feature {
        background: linear-gradient(90deg, #141414 0%, #141414 15%, #000 15%, rgba(255, 255, 255, 0) 50%), #000;
        border: none;

        ul {
            li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            }
        }
    }

    .opportunity-icon-list {
        li {
            background-color: #000000;

            svg {
                color: white;
            }
        }
    }

    .package-card-view-button {
        >div {
            background: #000;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

            .package-card {
                box-shadow: none;
            }
        }


    }
}

@media screen and (max-width:767px) {


    .calc-item {
        padding-right: 0;
        margin-bottom: 20px;
    }

    .package-image {
        img {
            height: auto;
        }
    }

    .package-section {
        .active-package-list {
            height: auto !important;
        }

        .dash-bg-card {
            margin-top: 30px;
        }

        .demo-bg {
            height: auto !important;

            .heading {
                position: absolute;
                top: 2%;
                left: 4%;
                color: $white;
            }

            .demo {
                padding: 50px 10px;

                .slick-slider {
                    svg {
                        display: none !important;
                    }
                }

                .slick-dots {
                    li {
                        margin-right: 8px !important;
                    }
                }
            }
        }

        .package-card-view {
            .package-card {
                flex-direction: column;

                .content {
                    width: 98%;
                }
            }
        }

        .active-package-list {
            min-height: 100%;
        }
    }

    .responsive-package-list {
        justify-content: center;
    }

    .purchase-now-card {

        .modal-content {
            width: 100%;
        }

        .package-card-view {
            width: 100%;

            .package-card {
                flex-direction: column;
            }
        }
    }

    .opportunity-icon-list {
        padding: 0;
    }

    .package-card-view-button {
        >div {
            padding: 20px;
            min-height: auto;
            border-radius: 16px;

            .package-card {
                width: 100% !important;
            }
        }


    }

    .product-feature {
        background: linear-gradient(90deg, #F8FAFF 0%, #F8FAFF 30%, #FFF 30%, rgba(255, 255, 255, 0.00) 50%), #FFF;
        padding: 40px 10px 10px 10px;
    }

    [data-bs-theme="dark"] {

        .product-feature {
            background: linear-gradient(90deg, #141414 0%, #141414 30%, #000 30%, rgba(255, 255, 255, 0) 50%), #000;
        }
    }
}