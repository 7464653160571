.profile_header {
    text-align: center;
    display: inline-block;

    .profile_photo {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background: #F0F2F7;
        border: 1px solid rgba(0, 0, 0, 0.15);
        @include flexcenter;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        img {
            width: 96px;
            height: 96px;
            border-radius: 50%;
        }

        .profile_edit {
            width: 100%;
            height: 100%;
            flex-shrink: 0;
            border-radius: 50%;
            flex-shrink: 0;
            @include flexcenter;
            position: absolute;
            display: none;

            svg {
                width: 20px;
            }
        }

        &:hover {
            .profile_edit {
                display: flex;
                background-color: rgba(0, 0, 0, 0.15);
            }
        }

    }

    span {
        color: #525866;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
    }


}

.profile-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    svg {
        width: 20px;
    }

    .content {
        h4 {
            color: #7B8599;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
            margin: 0;
        }

        span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }


}

.profile-info-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    list-style-type: none;
    padding: 0;

    li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
        width: 100%;

        .icon-form-group {
            width: 100%;

        }

        .icon-input {
            width: 100%;
        }

        button {
            border-radius: 12px;
            text-wrap: nowrap;
        }

        .icon-input {
            margin: 0;
        }
    }
}

.kyc-box {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid rgba(255, 0, 43, 0.10);
    background: #FFF2F4;
    box-shadow: 0px 4px 16px 0px rgba(255, 0, 43, 0.10);
    position: relative;

    .ribbon {
        background: $primary;
        position: absolute;
        left: -16px;
        transform: translate(0%, 20%);

        &::after {
            background: #FFF2F4;
        }
    }

    p {
        margin: 0;
        margin-top: 40px;
    }

    a {
        @include flexcenter;
        padding: 8px 16px;
        gap: 8px;
        color: #000;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        text-transform: uppercase;
    }
}

.Resend {
    font-size: 14px;

    p {
        margin: 0;
        margin-top: 5px;
    }

    span {
        font-size: 14px;
    }
}

.profile-bg-card {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #FFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

[data-bs-theme="dark"] {
    .profile-bg-card {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #000000;
        box-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 0.1), 0px 0px 5px 0px rgba(255, 255, 255, 0.1);
    }

    .kyc-box {
        border: 1px solid rgba(255, 0, 43, 0.10);
        background: #1c0301;
        box-shadow: 0px 4px 16px 0px rgba(255, 0, 43, 0.10);

        .ribbon {
            &::after {
                background: #1c0301;
            }
        }

        p {
            margin: 0;
            margin-top: 40px;
        }

        a {
            @include flexcenter;
            padding: 8px 16px;
            gap: 8px;
            color: #000;
            border-radius: 16px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            background: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: uppercase;
        }
    }
}

@media screen and (max-width:767px) {
    .profile-info-list {

        li {
            flex-wrap: wrap;

            button {
                width: 100%;
            }
        }
    }
}