#dashboard-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  z-index: 2;


  .header1 {
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px;
    background-color: #FFF;
    z-index: 1;
    padding: 10px 0;
    min-height: 87px;
    display: flex;
    align-items: center;

    .time {
      color: $primary;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      line-height: 18px;
      max-width: 211px;

      span {
        color: $black;
      }
    }

  }

  .header_nav_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
}





.nav-right {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .nav-right-item {
    margin-left: 1.2rem;
  }

  .border-left {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  .user-logged-in {
    display: flex;
    align-items: center;

    >span {
      font-size: 3rem;
      margin-left: 10px;
      color: $primary;
    }

    .info {

      padding-left: .8rem;
      color: $black;
      text-align: left;
      margin-right: 22px;

      h4 {
        margin: 0;
        font-size: 17px;
        font-weight: 600;
        color: $primary;
        text-transform: capitalize;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: $gray;
      }
    }

    .photo {
      margin-right: 0;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        // @include gradientReverse();
        padding: 1px;
      }

      img {
        width: 48px;
        height: 50px;
        border-radius: 50%;
      }

      span {
        width: 50px;
        height: 50px;
        // background-color: $primary;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}


.header-notifications {
  padding: 0 10px 0 0;

  >div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(186, 170, 98);
    background: linear-gradient(5deg, rgba(186, 170, 98, 1) 0%, rgba(221, 213, 178, 1) 50%, rgba(255, 255, 255, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .inner-white {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFF;
    }

    svg {
      width: 16px;
    }

    i {
      color: $black;
    }

    span {
      position: absolute;
      top: -10px;
      right: -12px;
      background-color: #1F1F1F;
      color: #FFF;
      width: 27px;
      height: 19px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }
}



.dropdown_head_button {
  padding: 0;
  background-color: transparent !important;
  border: none;

  &:focus,
  &:active {
    background-color: transparent;
  }

  &.dropdown-toggle {
    &::after {
      display: none;
    }
  }
}

.notifications_dropdown {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  height: 600px;
  border-radius: 34px;
  padding: 4px;
  top: 19px !important;
  right: -219px !important;
  width: 540px;
  font-size: 16px;
  line-height: 20px;

  border: none;
  transition: all 0.2s ease 0s;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 16px 16px 16px;
  //   border-color: transparent transparent $primary transparent;
  //   top: -13px;
  //   left: 48%;
  //   right: 0;
  // }

  .inner_noifications {
    overflow: hidden;
    border-radius: 30px;
    background: $lightGray;
  }

  ul {
    margin: 20px;
    padding: 0;
    list-style-type: none;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 490px;

    &:hover {
      &::-webkit-scrollbar-thumb {
        display: block;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
    }

    &::-webkit-scrollbar {
      width: 4px;

    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 50px;
      display: none;
    }
  }
}

.header_user_dropdown {
  @include gradientReverse();
  max-height: calc(100vh - 200px);
  background: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 3px;
  top: 17px !important;
  right: 0;
  width: 300px;
  font-size: 16px;
  line-height: 20px;
  border: none;
  transition: all 0.2s ease 0s;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 0 16px 16px 16px;
  //   border-color: transparent transparent $primary transparent;
  //   top: -13px;
  //   left: 42%;
  //   right: 0;
  // }

  &.lang-dd {
    width: 200px;
    margin-left: -50px;

    &::before {
      left: 31%;
    }

    .inner {
      padding: 30px;
    }
  }

  .inner {
    background-color: $lightGray;
    padding: 40px;
    border-radius: 28px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;

      &.divider {
        @include gradientReverse();
        height: 2px;
        margin: 20px 0;
      }

      .dd_menu_item {
        display: flex;
        align-items: center;
        color: #8D8D8D;

        &:hover {
          color: $primary;

          svg {
            path {
              fill: $primary !important;
            }
          }
        }

        svg {
          width: 20px;
          height: 20px;
          margin-right: 22px;

          path {
            fill: #8D8D8D;
          }
        }

        i {
          width: 40px;
        }
      }
    }
  }



}


.dd_user {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;


  .info {

    padding-left: .8rem;
    color: $black;

    h4 {
      margin: 0;
      font-weight: bold;
      color: #000;
      font-size: 1.3rem;
      text-transform: capitalize;
    }

    p {
      margin: 0;
      font-size: .8rem;
    }
  }

  .photo {
    img {
      width: 52px;
      height: 52px;
      border-radius: 50%;
    }

    span {
      width: 50px;
      height: 50px;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.menu-wrap {
  display: none;
}

.header-center-items {
  margin: 0 14px 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    .inner {
      background-color: $lightGray;
      padding: 11px 12px;
      border-radius: 8px;
      min-width: 224px;
      height: 48px;
      display: flex;
      align-items: center;
    }

    a {
      color: $primary;
      font-size: 14px;
      font-weight: 600;

      span {
        color: $black;
      }
    }

    p {
      color: $primary;
      font-size: 14px;
      font-weight: 600;
      margin: 0;

      span {
        color: $black;
      }

      &.time {
        max-width: 200px;
      }
    }
  }
}


.dropdown_language {
  margin-right: 20px;

  .dropdown {

    .btn-primary {
      background-color: transparent;
      padding: 8px;
      border: none;
      color: #000;
      font-size: 13px;
      font-weight: bold;

      &::after {
        display: none;
      }

      span {
        margin-left: 4px;
      }
    }


  }

  .dd_menu_item {
    img {
      width: 22px;
      margin-right: 10px;
    }
  }
}

.mobile-menu {
  display: none;
}

#circle1 {
  position: absolute;
  top: 0px;
  right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 30px;

  &.green {
    background-color: transparent;
  }

  &.red {
    background-color: black;
  }
}

#circle {
  &.black {
    color: transparent;
    background-color: transparent;
  }
}


@media screen and (max-width: 767px) {

  #circle1 {
    top: -1px;
    right: 0px;
  }

  .mobile-menu {
    display: block;

    .notifications_dropdown {
      width: 320px;
      height: 440px;
      left: -191px !important;
      right: unset;
    }

    .notifications_dropdown {
      ul {
        height: 363px;
        padding: 0;
        margin: 12px;
      }

      &::before {
        display: none;
      }
    }

    .header_user_dropdown {
      position: absolute;
      inset: 0px 0px auto auto;
      transform: translate3d(5px, 30px, 0px) !important;
      width: 255px;

      &::before {
        display: none;
      }

      &.lang-dd {
        width: 200px;
        margin-left: -50px;


        &::before {
          left: 73%;
        }

        .inner {
          padding: 20px;
        }
      }

      .inner {
        background-color: $lightGray;
        padding: 20px;
        border-radius: 28px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          margin-bottom: 10px;

          .dd_menu_item {

            padding-bottom: 0 !important;
          }
        }
      }
    }

    .header-notifications {
      >div {
        width: 32px;
        height: 32px;

        span {
          width: 16px;
          height: 16px;
          font-size: 11px;
        }

        .inner-white {
          width: 30px;
          height: 30px;

          svg {
            width: 15px;
          }
        }
      }
    }

  }





  .ham {
    display: block;
  }

  .dropdown_language {
    margin-right: 5px;
  }

  .header-notifications {
    padding: 0 5px 0 0;
  }

  #dashboard-header {


    .header1 {
      padding: 0;
      min-height: 58px;

      .time {
        display: none;
        font-weight: 500;
        font-size: 11px;
        max-width: 100%;
        margin: 6px 0 0 8px;
      }
    }


  }


  .header-center-items {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin: 0 !important;
    margin-bottom: 2px !important;
    margin-top: 10px !important;
    padding: 0 10px;

    li {
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin-top: 0px;
      margin-bottom: 5px;
      margin-right: 0px;

      &:last-child {
        margin-top: 0px !important;
        margin-right: 0;
      }

      .inner {
        padding: 3px 10px !important;
        height: 28px !important;
        min-width: 90vw !important;
      }

      a {
        color: $primary;
        font-size: 14px;
        font-weight: 600;

        span {
          color: $black;
        }
      }

      p {
        color: $primary;
        font-size: 14px;
        font-weight: 600;
        margin: 0;

        span {
          color: $black;
        }

        &.time {
          max-width: 200px;
        }
      }
    }
  }

  .mobile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .destop-logo {
      display: none;
    }

    .mobile-logo {
      display: block;
      width: 70px;
    }

  }

  .server-time {
    display: none;
  }

  .menu-wrap {
    display: block;
  }

  .cryptohopper-web-widget {
    display: none;
  }

  #dashboard-header {
    padding: 0;

    .header1 {}
  }


  .nav-left {
    .nav-logo {
      margin-right: 0;
    }



    >a {
      display: none;
    }
  }

  .nav-right {
    align-items: center;
    position: relative;
    top: 2px;
    display: flex;
    right: 2px;

    .user-logged-in {
      .photo {
        span {
          width: 35px;
          height: 35px;

          img {
            width: 33px;
            height: 35px;
          }
        }

      }

      .info {
        display: none;
      }

      >span {
        display: none;
      }
    }
  }

  .new_members_wrap {
    display: none;
  }
}

@media screen and (max-width: 422px) {
  .mobile-menu {
    .notifications_dropdown {
      left: -28px !important;
    }
  }
}