#admin-body {
    height: 100vh;
    overflow: hidden;
}

.admin-title {
    padding: 8px 30px 0 30px;
    color: $black;

    h4 {
        margin: 0;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 30px;
    }
}

.admin-theme-icons {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    transition: background-color 0.2s;

    .iocn {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        @include flexcenter;
        border-radius: 32px;
        background: #FFF;
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 4px 16px 0px rgba(0, 0, 0, 0.02);

        .sun {
            stroke: $primary;
        }

        svg {
            width: 24px;
        }

    }
}

@mixin blackgradient() {
    background: rgb(0, 0, 0);
    background: linear-gradient(270deg, $lightGray 0%, $black 100%) !important;
}

.black-gradient {
    @include blackgradient();
    border: 1px dashed black;

    .form_default {

        .form-group {

            .inputwrap {
                @include blackgradient();
            }
        }
    }
}

.admin-modal-theme {

    &.size-md {
        .modal-dialog {
            max-width: 500px;
        }
    }

    .modal-content {
        @include blackgradient();
        padding: 2px;
        border-radius: 30px;
    }

    .modal-header {
        background-color: $lightGray;
        padding: 14px 30px;
        border-radius: 28px 28px 0 0;
        border: none;
        position: relative;

        &.header-dark {
            @include gradientDark();
            margin: -2px;

            .modal-title {
                color: #FFF;
            }

            .btn-close {
                filter: invert(100%);
            }
        }

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            @include gradientReverse();
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .modal-title {
            font-size: 18px;
            font-weight: bold;
            color: #000;
        }
    }

    &.modal-small {
        .modal-dialog {
            max-width: 550px;
        }
    }


    &.modal-lg {
        .modal-dialog {
            max-width: 960px;

        }
    }

    &.modal-thumbnail {
        .modal-dialog {
            iframe {
                width: 100%;
                height: 397px;
                border-radius: 25px;
            }
        }
    }


    .modal-dialog {
        max-width: 800px;

    }

    .modal-body {
        padding: 2.2rem 2rem;
        background-color: $lightGray;
        border-radius: 0 0 28px 28px;
    }

    .modal-footer {
        padding: 1.2rem 2rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
}


.title-bar {

    h4 {
        color: #FFF;
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 0;
    }
}

.admin-card {
    border-radius: 10px;
    color: $white;
    margin-bottom: 25px;
    font-family: $poppin;
    transition: all 0.4s ease-in-out;

    .card-inner {
        padding: 10px;
        border-radius: 20px;
        position: relative;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #FFF;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        padding: 16px;
        gap: 10px;
        width: 100%;

        .icon {
            padding-top: 20px;

            span {
                color: #c5c5c5;
                font-size: 50px;
            }
        }

        .value {
            min-width: 50%;
        }

        h2 {
            font-family: $font-number;
            font-weight: 600;
            text-align: center;
            font-size: 1.1rem;
            color: $black;
            border-radius: 20px;
            background: linear-gradient(91deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
            color: #FFF;
            padding: 9px 20px;
            margin: 0;
        }

        p {
            font-size: 1rem;
            text-align: center;
            text-transform: capitalize !important;
            color: $black;
            margin: 0;
            font-family: $poppin;
        }

        h6 {
            color: $black;
            font-weight: 400;
            position: absolute;
            top: 25px;
            right: 16px;
            transform: translate(0%, -50%);
        }


        &:hover {
            .icon {

                span {
                    color: $primary;
                }
            }
        }

        &.blue,
        &.red,
        &.green,
        &.brown {
            background: $white;
        }
    }

    &:hover {
        .card-inner {
            border: 1px solid rgb(255, 21, 0);
        }
    }
}

.franchise-card-popup {
    position: relative;

    .popup-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $primary;
        @include flexcenter;
        font-size: 13px;
        position: absolute;
        top: 20px;
        right: 20px;
        color: $white;
        z-index: 5;
        cursor: pointer;
    }
}

.scheduler {
    .sub_heading {
        font-size: 1rem !important;
    }

    .card-inner {
        .d-title {
            font-size: 1.1rem !important;

            span {
                font-size: .9rem;
            }
        }
    }

    .d-title {
        font-size: 1.3rem !important;
    }

    .button {
        padding: 0.4rem .5rem !important;
    }

    .gray-outline {
        // background: #adadad;
        // box-shadow: 1px 1px 15px -6px black;
    }
}

.header-admin {
    .navbar-brand {
        img {
            width: 65px;
        }
    }


    .server-time {
        margin-left: 100px;

        .time {
            color: $black;
            font-weight: bold;
            font-size: 14px;
            margin: 0;
            line-height: 18px;
            max-width: 211px;

            span {
                color: $black;
                font-weight: 600;
            }
        }

    }

    .navbar-nav {
        .nav-link {
            padding: 10px 15px;
            font-size: 15px;
            color: $smgray;
        }
    }

    .admin_header_right {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
            margin-left: 20px;

            .dd_menu_item {
                color: $black;
            }

            .admin_logged_in_user {
                display: flex;
                align-items: center;
                cursor: pointer;

                span {
                    width: 40px;
                    height: 40px;
                    background-color: $white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    color: $white;
                    margin-right: 10px;
                    border: 1px solid $primary;
                    overflow: hidden;

                    img {
                        width: 80%;
                    }
                }

                h4 {
                    margin: 0;
                    font-size: 16px;
                    color: #000;
                }

                p {
                    margin: 0;
                    font-size: 13px;
                    color: #666;
                }
            }
        }
    }
}

.admin-navbar {
    display: none;
}

#dropdownUserLoggedIn {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: transparent;
    border: none;

    &::after {
        display: none !important;
    }

}

.black-tick {
    margin: 0 !important;
}

.loding-div {
    background: rgb(0 0 0 / 48%);
    width: 100vw;
    height: 100vh;
}

.header-admin {
    .dropdown-menu {
        border: none;
        top: 57px !important;
        border-radius: 0;
        box-shadow: 0px 0px 6px 0px #0000001f;

        .dropdown-item {
            display: flex;
            align-items: center;

            i {
                font-size: 1rem;
                margin-right: .6rem;
            }

            span {
                font-size: .9rem;
            }
        }
    }

}

.admin_login_wrap {
    background-image: url("../images/admin/bg-body.png");
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 15px -6px black;

    .login_box {
        width: 450px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 116px;
            margin-bottom: 10px;
        }

        .login_box_head {
            background-color: $black;
            box-shadow: 20px 20px 42px 8px #cecece;
            padding: 20px;
            text-align: center;
            color: $white;
            width: 100%;

            h4 {
                margin: 10px 0 0 0;
                font-size: 20px;
            }

            p {
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 0;
            }
        }

        .login_box_body {
            width: 100%;
            background-color: #FFF;
            border-radius: 0 0 6px 6px;
            padding: 40px;
            box-shadow: 1px 1px 15px -6px black;

            .inputwrap {
                background: $black !important;
                padding: 1px;

                .lock-icon {
                    &::before {
                        background: linear-gradient(0deg, rgb(231, 232, 231) 0%, rgb(0 0 0) 100%) !important;
                        width: 1px !important;
                    }
                }
            }
        }
    }
}


.admin_card_accordian {
    background-color: transparent;
    border: none;

    .accordion-item {
        border: none;
        background-color: transparent;

        .accordion-header {
            .accordion-button {
                background: rgb(86, 83, 61);
                background: linear-gradient(90deg, rgba(86, 83, 61, 1) 0%, rgba(17, 25, 37, 1) 100%);
                padding: 1.5rem;
                color: #FFF;
                font-weight: 400;
                font-size: 20px;

                &:focus {
                    box-shadow: none;
                }

                &::after {
                    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
                }
            }

            .accordion-button:not(.collapsed)::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
            }
        }

        .accordion-body {
            background-color: transparent;
            border: none;
            padding: 20px 0;
        }
    }
}

.admin-wrap {
    display: flex;



    &.show {
        .admin-left {

            width: 100px !important;

            li {

                a {
                    span {
                        display: none;
                    }
                }

                .accordion {
                    transition: none 0s ease 0s !important;
                }

                .accordion-item {

                    .accordion-header {

                        p {
                            display: none !important;
                        }

                        .accordion-button {
                            background-color: $black;
                            color: $black;
                            position: relative;

                            &::after {
                                width: 6px;
                                height: 6px;
                                margin-left: -5px;
                            }
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            .accordion-button {
                                &::after {
                                    background-image: none !important;
                                    background-color: $white !important;
                                }
                            }
                        }

                        .accordion-button:not(.collapsed) {

                            &::after {
                                background-image: none !important;
                                background-color: $white !important;
                            }
                        }

                        .accordion-button:not(.collapsed),
                        .accordion-button,
                        button {
                            padding: 0 16px;
                            border-radius: 5px;
                            background-color: #111925;
                            height: 52px;
                            font-size: 13px;

                            span {
                                width: 40px;
                                height: 40px;
                                font-weight: bold;
                                border-radius: 50%;
                                text-align: center;
                                background: $primary;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: $black;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                background-color: $primary;
                                z-index: 3;
                                border-color: transparent;
                                box-shadow: none;
                            }

                        }
                    }

                    .accordion-collapse.collapse.show,
                    .accordion-item:last-of-type .accordion-collapse,
                    .accordion-collapse.collapsing,
                    .accordion-collapse.collapse {
                        position: fixed;
                        inset: 0px auto auto 35px;
                        margin: 0px;
                        transform: translate(61px, 550px);
                        z-index: 10;

                        .accordion-body {
                            border-radius: 16px;
                            padding: 8px !important;
                            border: 1px solid rgba(0, 0, 0, 0.10);
                            background: #FFF;
                            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 0px 5px 0px rgba(0, 0, 0, 0.10);

                            .sub-menu {

                                a {
                                    span {
                                        display: block !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .admin-right {
            width: calc(100vw - 100px) !important;
        }
    }

    .admin-left {
        width: 16.5%;
        background-color: $white;
        height: calc(100vh - 69px);
        overflow-y: scroll;
        position: relative;

        &:hover {
            &::-webkit-scrollbar-thumb {
                display: block;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            }
        }

        &::-webkit-scrollbar {
            width: 4px;

        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 50px;
            display: none;
        }

        .admin_side_nav {
            margin: 0;
            padding: 20px 20px;
            list-style-type: none;
            padding-top: 0;

            >li {
                margin-top: 5px;

                &:first-child {
                    margin-top: 0 !important;

                    a {
                        background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                    }

                }

                a {
                    font-family: $poppin;
                    font-size: 13px;
                    color: $black;
                    padding: 6px 16px;
                    display: flex;
                    align-items: center;
                    border-radius: 50px;
                    font-weight: 500;

                    &:hover,
                    &.active,
                    &:focus {
                        background-color: $primary;
                        background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                        color: $white;
                    }

                    i {
                        line-height: unset;
                        margin-right: 10px;
                    }
                }
            }

            .accordion {
                border: none;
                font-family: $poppin;
                font-size: 13px;
                font-weight: 500;
                background-color: transparent !important;
                margin-top: 5px;

                .accordion-item {
                    border: none;
                    background-color: transparent !important;
                    color: $black;

                    .collapsed {
                        border-radius: 5px;
                        font-size: 13px;
                        font-weight: 500;
                    }

                    &:last-of-type {
                        border-radius: 5px;
                        font-size: 13px;
                        font-weight: 500;
                    }

                    .accordion-header {
                        padding: 0;
                        background-color: transparent;
                        font-size: 13px;
                        font-weight: 500;
                        color: $black;
                        margin-bottom: 4px;

                        i {
                            margin-right: 10px;
                        }

                        p {
                            margin: 0;
                        }

                        .accordion-button {
                            background: transparent;
                            color: $black;


                            &::after {
                                position: relative;
                                height: 8px;
                                width: 8px;
                                border-radius: 50%;
                                background-color: $primary;
                                background-image: none !important;
                            }
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            .accordion-button {
                                background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                                color: $white;

                                &::after {
                                    background-image: none !important;
                                    background-color: $white;
                                }
                            }
                        }

                        .accordion-button:not(.collapsed) {
                            background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%) !important;
                            color: $white !important;
                            font-size: 13px;
                            font-weight: 500;

                            &::after {
                                background-image: none !important;
                                background-color: $white;
                            }
                        }

                        .accordion-button:not(.collapsed),
                        .accordion-button,
                        button {
                            padding: 0 16px;
                            border-radius: 50px;
                            background: transparent;
                            color: #000;
                            box-shadow: none;
                            height: 52px;
                            font-size: 13px;

                            span {
                                width: 40px;
                                height: 40px;
                                font-weight: bold;
                                border-radius: 50%;
                                text-align: center;
                                background: $primary;
                                color: $white;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            &::after {
                                background-image: none !important;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                                color: #fff;
                                z-index: 3;
                                border-color: transparent;
                                box-shadow: none;

                                &::after {
                                    background-image: none !important;
                                    background-color: $white;
                                }

                            }

                        }
                    }

                    .accordion-collapse.collapse.show,
                    .accordion-item:last-of-type .accordion-collapse,
                    .accordion-collapse.collapsing,
                    .accordion-collapse.collapse {
                        .accordion-body {
                            padding: 8px 1px 8px 40px;
                            background-color: $white;

                            .sub-menu {
                                list-style: none;
                                margin: 0;
                                background: transparent;
                                color: $black;
                                min-height: 48px;
                                border-radius: 50px;

                                a {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                    padding: 6px 16px;
                                    font-size: 13px;
                                    border-radius: 50px;
                                    color: $black;
                                    margin-top: 5px;
                                    min-height: 52px;


                                    &:hover,
                                    &:focus,
                                    &:active,
                                    &:target {
                                        i {
                                            &::after {
                                                background: $white;
                                            }
                                        }
                                    }

                                    i {
                                        position: relative;
                                        margin-right: 10px;

                                        &::after {
                                            content: '';
                                            position: absolute;
                                            width: 5px;
                                            height: 5px;
                                            border-radius: 10px;
                                            background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                                            top: -2px;
                                            left: -6px;
                                        }

                                    }


                                    &:hover,
                                    &:focus,
                                    &:active,
                                    &:target {
                                        background: linear-gradient(0deg, #FF1500 0%, #FF002B 49.48%, #FF4060 98.97%);
                                        color: $white;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .admin-right {
        width: 83.5% !important;
        background-color: #f8f9fa;

        .responsive-table {
            overflow-x: scroll;
            overflow-y: hidden;

            .table {
                border-collapse: collapse;

                th,
                td {
                    white-space: nowrap;
                }
            }
        }

        >.admin-content {
            overflow-y: scroll;
            height: calc(100vh - 69px);
            padding: 30px 30px 150px 30px;
        }
    }
}

.admin-statement {
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #FFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding: 16px;

    .head {
        background-color: #f0f2f7 !important;
        color: #000;
        padding: 11px;
        border-radius: 30px;
    }

    h4 {
        margin: 0 0 0 10px;
        font-size: 18px;
    }


    .admin-statement-table {}
}

.form_admin {
    .form-group {
        position: relative;

        label {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .form-control,
        input,
        textarea {
            display: flex;
            width: 100%;
            padding: 16px;
            justify-content: space-between;
            align-items: center;
            border-radius: 12px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            padding-right: 41px;

            &.disabled {
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: rgba(0, 0, 0, 0.05);
            }

            &:focus-visible,
            &:focus,
            &:active {
                border: 1px solid $primary;
                outline: $primary;
                box-shadow: none;
            }

            &::placeholder {
                color: rgba(0, 0, 0, 0.6) !important;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.5px;
            }

            &:disabled {
                border: 1px solid rgba(0, 0, 0, 0.15);
                background: rgba(0, 0, 0, 0.05);
            }

            >div,
            &.form-control {
                text-overflow: ellipsis;
            }

        }

        // .form-control,
        // .form-select {
        //     border: 1px solid #ced4da;
        //     border-radius: 8px;
        //     background-color: white;
        //     min-height: 36px;

        //     &:active,
        //     &:focus,
        //     &:target,
        //     &:focus-visible {
        //         border-color: #ced4da !important;
        //         border: 1px solid #ced4da;
        //         box-shadow: 0 0 0 0.25rem rgba(203, 152, 42, 0.315) !important;
        //     }

        //     &.disabled {
        //         background-color: #f3f2f2;
        //         opacity: 1;

        //     }

        // }

        // input,
        // select {

        //     &:active,
        //     &:focus,
        //     &:target,
        //     &:focus-visible {
        //         box-shadow: none !important;
        //         outline: none !important;
        //         border: none;
        //         border-color: transparent !important;
        //     }
        // }
    }
}

.user-card {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;

    .front,
    .back {
        width: 48%;
        height: auto;
        border: 1px solid #ccc;
        display: flex;
        margin: auto;

        img {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 8px;

        }
    }


}

.admin-inputwrap {
    // background: rgb(180, 166, 102);
    // background: linear-gradient(80deg, rgb(190, 169, 75) 0%, rgb(143, 140, 114) 50%, rgba(255, 255, 255, 0) 100%);
    padding: 2px;
    // border-radius: 12px;

    .inner {
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        height: 40px;
        padding: 8px;
    }

}


.admin-card-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
    color: $smgray;
    // text-transform: uppercase;

    span {
        color: #000;
    }
}

.admin-profile-input {
    .form_default .form-group .inputwrap {
        // background: linear-gradient(270deg, #FAFAFA 0%, rgb(187 187 187) 100%) !important;
    }
}

.scheduler {
    .d-title {

        margin-bottom: -13px;
    }
}

.sub_heading {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: -10px;
    color: rgb(116 116 116);
}

.password-view {
    margin-top: 5px;
    text-align: end;

    span {
        color: $black;
        margin: 5px;
        font-size: 13px;
        cursor: pointer;
    }
}

@media screen and (max-width:767px) {

    .admin-title {
        h4 {
            font-size: 20px;
            margin-bottom: 13px;
        }
    }

    .admin_login_wrap {
        .login_box {
            width: 380px;
            margin-top: 20%;
        }
    }



    .admin-card-title {
        font-size: 16px;

        span {
            font-size: 16px;
        }
    }

    .admin-navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        z-index: 10;
        background-color: white;

        .admin_logged_in_user {
            display: flex;
            align-items: center;

            span {
                width: 40px;
                height: 40px;
                background-color: $white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                color: $white;
                margin-right: 10px;
                border: 1px solid $primary;
                overflow: hidden;

                img {
                    width: 80%;
                }
            }

            h4 {
                margin: 0;
                font-size: 16px;
                color: #000;
            }

            p {
                margin: 0;
                font-size: 13px;
                color: #666;
            }
        }
    }

    .admin-wrap {
        display: flex;

        &.show {
            .admin-left {
                width: 280px !important;

                li {


                    a {
                        span {
                            display: block;
                        }
                    }

                    .accordion {
                        .accordion-item {
                            .accordion-header {
                                p {
                                    display: block !important;
                                }
                            }

                            .accordion-collapse.collapse.show,
                            .accordion-item:last-of-type .accordion-collapse,
                            .accordion-collapse.collapsing,
                            .accordion-collapse.collapse {
                                position: relative;
                                inset: 0px auto auto 0px;
                                margin-top: 5px;
                                transform: none;
                                z-index: 10;

                                .accordion-body {

                                    padding: 8px !important;
                                    border-radius: 4px !important;
                                    border: 1px transparent !important;

                                    .sub-menu {

                                        a {
                                            span {
                                                display: block !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }

        .admin-left {
            width: 280px !important;
            position: absolute;
            height: calc(100% - 69px);
            transform: translateX(-100%);
            transition: all .5s ease;
            z-index: 9;

            &.active {
                transform: translateX(0);
            }

            .accordion {
                .accordion-header {

                    .accordion-button:not(.collapsed),
                    .accordion-button,
                    button {
                        height: 39px !important;
                    }
                }

                .accordion-collapse.collapse.show,
                .accordion-item:last-of-type .accordion-collapse,
                .accordion-collapse.collapsing,
                .accordion-collapse.collapse {
                    .accordion-body {
                        padding: 0px 1px 7px 30px;

                        .sub-menu {
                            a {
                                min-height: 39px !important;
                            }
                        }
                    }
                }
            }

            .admin_side_nav {
                overflow-y: scroll;

                >li {
                    &:first-child {
                        display: none;
                    }

                    a {
                        font-size: 22px;

                        i {
                            font-size: 18px;
                        }

                        span {
                            display: block;
                        }
                    }
                }
            }
        }

        .admin-right {
            width: 100% !important;

            >.admin-content {
                padding: 5px 30px 300px 30px;
            }
        }
    }

}