.d-title {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 14px;
}



.gradient-num {
    @include gradientReverse();
    padding: 1px;
    border-radius: 6px;

    span {
        border-radius: 4px;
        background-color: $lightGray;
        color: #000;
        min-height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.section-detail {
    font-size: .9rem;
}

.section-title {
    font-family: $font-bebas;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 50px;


    &.title-sm {
        font-size: 3rem;
    }
}

.icon_status {
    text-align: center;

    .tick {
        margin-left: 0 !important;
    }
}

.text-gray {
    color: $gray !important;
}

.text-green {
    color: $green;
}

.text-primary {
    color: $primary !important;
}

.sub-heading {
    max-width: 700px;
    margin: auto;
    font-size: 1.3rem;
}

.title {
    &.center {
        text-align: center;

        h3 {
            margin: auto;
        }
    }

    p {
        color: $primary;
        font-weight: 500;
        margin: 0;
    }

    h3 {
        font-family: $font-bebas;
        font-size: 3rem;
        max-width: 400px;
    }
}

.promo-section {
    padding-top: 0 !important;
    position: relative;

    .button {
        position: absolute;
        top: 38%;
        right: 5%;
        background: #6e6e6e;
        color: white;
    }
}



.progress {
    height: 9px;
    background-color: #BFBFBF;
}

.progress-bar-striped {
    background-image: none;
    height: 9px;
    border-radius: 8px;
}

.offcanvas-custom {
    &.size-40 {
        width: 510px;
    }

    .offcanvas-header {
        padding: 2rem 2.3rem;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    .offcanvas-body {
        padding: 3rem;

        h5 {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgb(142, 142, 142);
        }
    }
}


.icon-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 2rem;
        margin-bottom: .8rem;

        span {
            position: absolute;
            left: 0;
            top: 0;
            color: $primary;
        }
    }
}

.icon-list-2 {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        display: flex;
        margin-bottom: 3rem;

        .icon {
            width: 120px;

            img {
                width: 100%;
            }
        }

        .info {
            padding-left: 2rem;

            h4 {
                color: #FFF;
                margin: 0 0 .1rem 0;
                font-size: 1.2rem;
                text-transform: uppercase;
            }

            p {
                margin: 0;
                color: #c6c9e5;
                font-size: .9rem;
                max-width: 300px;
            }
        }
    }
}

.icon-bullet {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 1.5rem;
        margin-bottom: .6rem;

        &::before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
            background-color: $primary;
        }
    }
}

.placehoder-loader {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;

    &.fullpage {
        position: absolute;
        height: 100%;
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 1);
        z-index: 9;

        &.bg-white {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }
}

.pills-cyan {
    .nav-item {
        .nav-link {
            color: rgba(0, 0, 0, 1);
            font-size: 15px;
            padding: 18px 16px;
            border-radius: 12px 12px 0 0;
            border: none;
            font-weight: 500;

            &.active {
                @include gradientDark();
                color: #FFF;

            }


        }
    }
}


.pills-golden {
    .nav-pills {
        .nav-link {
            color: #CCD3D3;
            border-radius: 0;

            &:hover {
                color: $primary;
            }

            &.active {
                background-color: transparent;
                color: $primary;
                font-weight: 500;
                border-bottom: 2px solid $primary;
            }
        }
    }
}

// .mobile-bg {
//     height: 100vh;
// }
// .desktop-bg {
//     height: 100vh;
// }


@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.simple-section {
    padding: 40px 0;

}

.gradient {
    background: rgb(180, 166, 102);
    background: linear-gradient(80deg, rgba(190, 169, 75, 1) 0%, rgba(143, 140, 114, 1) 50%, rgba(255, 255, 255, 0) 100%);
}

.website-section {
    padding: 10px 0px !important;
}


.inline-action {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0px;


    li {
        padding: 0px 4px;
        list-style: none !important;

        button,
        a {
            border-radius: 50% !important;
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            transition: 0.5s;
            font-size: 13px;

            span,
            i {
                font-size: 13px;
            }
        }

        .primary {
            border: 1px solid transparent;
            background: $primary;
            color: #ffffff;

            &:hover {
                border: 1px solid $primary;
                background: transparent;
                color: $primary;
            }
        }

        .red {
            border: 1px solid transparent;
            background: red;
            color: #ffffff;

            &:hover {
                border: 1px solid red;
                background: transparent;
                color: red;
            }
        }

        .black {
            border: 1px solid transparent;
            background: black;
            color: #ffffff;

            &:hover {
                border: 1px solid black;
                background: transparent;
                color: black;
            }
        }
        .gray {
            border: 1px solid transparent;
            background: $gray;
            color: #000000;

            &:hover {
                border: 1px solid $gray;
                background: transparent;
                color: $gray;
            }
        }
    }
}

.blink {
    animation: blinker 2.7s linear infinite;
    font-size: 22px;
    font-weight: 500;
    color: black;
    font-style: italic;
    font-family: sans-serif;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    .promo-section {
        .button {
            top: 37%;
            right: 5%;
            padding: 2px;
            font-size: 6px;
            border-radius: 2px;
        }
    }

    .blink {
        font-size: 12px;
        margin-top: 34px !important;
    }

    .website-section {
        padding: 10px 12px !important;
    }

    .simple-section {
        padding: 10px 0px !important;
    }

    .table-button {
        display: flex;
        justify-content: center;

        .nav-item {
            width: 100% !important;
        }
    }

    .mobile_button {
        .button {
            margin: 5px !important;

            span {
                padding: 0.6rem .3rem;
                min-width: 105px;
                width: 100%;
            }
        }
    }

    .pills-cyan {

        .nav-item {

            .nav-link {
                font-size: 14px;
                padding: 6px 9px;
                border-radius: 12px 12px 0 0;
                font-weight: 500;
            }
        }

    }

    .mobile-nav-pills {
        overflow-y: hidden;
        overflow-x: scroll !important;
        height: 54px;
        display: flex !important;
        flex-wrap: nowrap;

        .nav-item {

            .nav-link {
                min-width: 139px !important;
                padding: 12px 9px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                text-align: center;
                justify-content: center;
            }

            &:nth-last-child(3) {
                min-width: 200px;
            }

            &:nth-last-child(2) {
                min-width: 200px;
            }

            &:last-child {
                min-width: 150px;
            }


        }

    }
}